import Logo from "../assets/img/logo.webp";
import Button from "react-bootstrap/Button";
import "../assets/styles/Login.css";
import { loadingImages } from "../components/LoadingImages.model";
import { Card, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { TbLogin } from "react-icons/tb";
import { oauthLoginUrl } from "../config/config";

const BackgroundImage = styled.div`
  ::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(5px);
  }

  background: url(${loadingImages[Math.floor(Math.random() * (loadingImages.length))]}) no-repeat center center fixed;
  background-size: cover !important;
  width: 100%;
  height: 100%;
  z-index: -999999;
  position: absolute;
`;

const Login = () => {

  return (
    <div className="App">
      <BackgroundImage></BackgroundImage>
      <div className="header"></div>
      <Card className="card-style">
        <Card.Body className="body-style">
          <img src={Logo} alt="Logo" className="image" />
          <Card.Subtitle className="mb-3 text-muted">MCA Logbot</Card.Subtitle>
          <Card.Title>Sign Up</Card.Title>
          <Row className="mt-2">
              <Col>
                <div className="d-grid">
                  <Button className="d-flex align-items-center justify-content-center" variant="primary" type="button" href={oauthLoginUrl}>
                    {<><TbLogin></TbLogin> Sign Up With Discord</>}
                  </Button>
                </div>
              </Col>
            </Row>
        </Card.Body>
      </Card>
    </div >
  );
}

export default Login;
