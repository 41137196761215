import { create } from "zustand";
type RedirectStore = {
  /**
   * location
   */
  redirect: string;
  setRedirect: (newLocation: string) => void;
};

export const useRedirectStore = create<RedirectStore>()((set) => ({
  redirect: "/dashboard" as string,
  setRedirect: (newredirect: string) => set(() => ({ redirect: newredirect })),
}));
