import ErrorAlert from "../alerts/ErrorAlert";
import { Pull } from "../../utils/pulls";
import Plot from "react-plotly.js";
import { Grid, useTheme } from "@mui/material";
import { getPlotlyFontColor } from "../../theme/theme";
import Tile from "../Tile";
import { useEffect, useState } from "react";


interface PullStatsProps {
  pull: Pull | null;
  sumOfBest: Record<string, any> | undefined;
  phase: string | undefined;
  onlyPull: boolean | undefined;
}

interface BestPullStats {
  phaseName: string;
  duration: number;
}

interface BestPhasesStats {
  phaseName: string;
  duration: number;
  link: string;
}

const PullStats: React.FC<PullStatsProps> = ({ pull, sumOfBest, phase, onlyPull }) => {
  const theme = useTheme();
  const fontColor = getPlotlyFontColor(theme.palette.mode);
  const [splitCharts, setsplitCharts] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setsplitCharts(window.innerWidth > 1000); // Adjust the threshold as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  if (pull === undefined || pull === null || phase === undefined || sumOfBest === undefined){
    return (
      <ErrorAlert>
        No pull data available.
      </ErrorAlert>
    );
  }
  if (onlyPull === undefined) {
    onlyPull = false;
  }
  
  const extractPhasesForName = (pull: Pull, phaseName: string): BestPullStats[] => {
    const phaseData = [];
    for (const [key, value] of Object.entries(pull.phases)) {
      if (key === "fullFight") {
        continue;
      }
      phaseData.push({
        phaseName: key,
        duration: value.duration,
      });
      if (key === phaseName) {
        break;
      }
    }
    return phaseData;
  }

  const extractSumOfBest = (sumOfBest: Record<string, any>, phaseName: string): BestPhasesStats[] => {
    const phaseData = [];
    for (const [key, value] of Object.entries(sumOfBest)) {
      if (key === "fullFight") {
        continue;
      }
      phaseData.push({
        phaseName: value.name,
        duration: value.duration,
        link: value.log,
      });
      //sum of best has lowercase keys without whitespace to avaiod duplicate phasenames bc of wrong naming from dps.report e.g. "25% -0%"" instead of "25% - 0%""
      if (key === phaseName.toLowerCase().replaceAll(" ", "")) {
        break;
      }
    }
    return phaseData;
  }

  const handleBestPullClick = (event: any) => {
    const href = pull.id;
    window.open(href, '_blank', 'noreferrer');
  };
  
  const bestPullPhases = extractPhasesForName(pull, phase);
  let sumOfBestDura = bestPullPhases.reduce((acc, cur) => acc + cur.duration, 0);
  sumOfBestDura= Math.round((sumOfBestDura + Number.EPSILON) * 1000) / 1000
  const sumOfBestPhases = extractSumOfBest(sumOfBest, phase);
  let sumOfBestPullDura = sumOfBestPhases.reduce((acc, cur) => acc + cur.duration, 0);
  sumOfBestPullDura = Math.round((sumOfBestPullDura + Number.EPSILON) * 1000) / 1000

  const handleSumBestClick = (event: any) => {
    const clickedPoint = event.points[0].pointNumber;
    if (clickedPoint > sumOfBestPhases.length) {
      return
    }
      const clickedData = sumOfBestPhases[clickedPoint];
      if (clickedData) {
          const href = clickedData.link;
        window.open(href, '_blank', 'noreferrer');
      }
    };
  
  const bestPie: Plotly.Data =
    {
      labels: bestPullPhases.map((phase: any) => phase.phaseName),
      values: bestPullPhases.map((phase: any) => phase.duration),
      type: 'pie',
      name: "Best Pull Phases",
    hoverinfo: 'label+value',
    textinfo: 'value',
    hole: .5,
    direction: 'clockwise',
    sort: false,
    
  };
  
  const sumBestPie: Plotly.Data =
  {
    labels: sumOfBestPhases.map((phase: any) => phase.phaseName),
    values: sumOfBestPhases.map((phase: any) => phase.duration),
    type: 'pie',
    name: "Sum of Best Pulls",
    hoverinfo: 'label+value',
    textinfo: 'value',
    hole: .5,
    direction: 'clockwise',
    sort: false,
  }

  var layout = {
    title: 'Best Pull',
    showlegend: false,
    annotations: [
      {
        text: `${sumOfBestDura}`,
        font: {
          size: 16,
          color: fontColor
        },
        showarrow: false,
        x: 0.5, // Position at the center horizontally
        y: 0.5, // Position at the center vertically
      },
    ],
    margin: { t: 30, b: 0, l: 5, r: 5 },
    align: "center",
    plot_bgcolor: 'rgba(255,255,255,0)',
    paper_bgcolor: 'rgba(255,255,255,0)',
    font: {
      color: fontColor
    }
  };
  var layout2 = {
    title: 'Sum Of Best',
    showlegend: false,
    annotations: [
      {
        text: `${sumOfBestPullDura}`,
        font: {
          size: 16,
          color: fontColor
        },
        showarrow: false,
        x: 0.5, // Position at the center horizontally
        y: 0.5, // Position at the center vertically
      }
    ],
    margin: { t: 30, b: 0, l: 5, r: 5 },
    plot_bgcolor: 'rgba(0,0,0,0)',
    paper_bgcolor: 'rgba(0,0,0,0)',
    font: {
      color: fontColor
    }
  };

  if (onlyPull) {
    layout["title"] = "";
    layout["margin"] = { t: 20, b: 0, l: 0, r: 0 }
    layout["align"] = "left"
    return < Plot data={[bestPie]} layout={layout} style={{ width: '100%', height: "100%" }} onClick={handleBestPullClick} useResizeHandler={true} />
  }
  
  return (
    splitCharts?
      (
        <Grid item xs={12} md={6} height={550}>
          <Tile name="Best Pull and Sum Of Best">
            <Grid container>
              < Plot data={[bestPie]} layout={layout} style={{ width: onlyPull ? '100%' : '50%', height: '100%' }} onClick={handleBestPullClick} useResizeHandler={true} />
              < Plot data={[sumBestPie]} layout={layout2} style={{ width: '50%', height: '100%' }} onClick={handleSumBestClick} useResizeHandler={true} />
              </Grid >
          </Tile>
        </Grid>
      )
      :
      (
        <Grid container item xs={12} sm={12} spacing={1}>
          <Grid item xs={12} sm={12} height={550}>
            <Tile name="Best Pull">
              <Plot data={[bestPie]} layout={layout} style={{ width: '100%', height: '100%' }} onClick={handleBestPullClick} useResizeHandler={true} />
            </Tile>
          </Grid>
          <Grid item xs={12} sm={12} height={550}>
            <Tile>
              <Plot data={[sumBestPie]} layout={layout2} style={{ width: '100%', height: '100%' }} onClick={handleSumBestClick} useResizeHandler={true} />
            </Tile>
          </Grid>
        </Grid>
      )
  );
};

export default PullStats;
