import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Tile from "../Tile";
import { TbInfoSquare } from "react-icons/tb";
import React, { useEffect } from "react";
import { Pull } from "../../utils/pulls";
import { FormControl, Grid, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import {renamePhase} from "../../utils/pulls"
import { useBoss } from "../../utils/bosses";
import { useBossSessions } from "../../utils/sessions";

interface FilterSessionPullsProps {
  pulls: Pull[] | undefined;
  // onDateRangeChange: (({ start_date, end_date }: { start_date: Date, end_date: Date }) => void) | undefined;
  onSessionsSelectChange: ((sessionIds: string[]) => void) | undefined;
  onCompChange: (comp: string[]) => void;
  onPhaseChange: (phase: string) => void;
  onPercentageChange: (percentage: number) => void;
  onNumberOfPlayersChange: (NumberOfPlayers: number) => void;
  onCMChange: (cm: boolean) => void;
  onLastXPullsChange: (lastXPulls: number) => void;
  filterNumberOfPlayers: number;
  isSessionOrProject: boolean;
  
}

type PullMode = "CM" | "Normal";

const FilterSessionPulls: React.FC<FilterSessionPullsProps> = ({ pulls, filterNumberOfPlayers,isSessionOrProject,onLastXPullsChange, onCompChange, onPhaseChange, onPercentageChange, onCMChange, onNumberOfPlayersChange, onSessionsSelectChange }) => {
  const { bossId: urlBossId, isCm:urlIsCm, sessionIds: urlSessionIds } = useParams();
  const [percentage, setPercentage] = React.useState<number>(100.1)
  const [phase, setPhase] = React.useState<string>("")
  const [cm, setCm] = React.useState<PullMode>(urlIsCm === "true" ? "CM" : "Normal")
  const [lastXPulls, setLastXPulls] = React.useState<number>(-1)
  const [NumberOfPlayers, setNumberOfPlayers] = React.useState<number>(filterNumberOfPlayers)
  const [comp, setComp] = React.useState<string[]>([...new Set(pulls?.map(pull => pull.comp))] || [])
  const [selectedSessions, setSelectedSessions] = React.useState<string[]>(urlSessionIds !== undefined ? urlSessionIds.split('+') : [])

  const { data: sessions } = useBossSessions(urlBossId as string, cm === "CM" ? true : false)
  
  const restrictSession = (typeof onSessionsSelectChange === 'undefined')
  const { data: bossData } = useBoss(urlBossId as string)
  
  
  // const { data, isLoading, isError } = useGuilds()

  //const { data } = usePullsDataByBoss(bossId as string, start_date, end_date)
  const percentages = React.useMemo(() => [0.0, 10, 25, 33, 50, 66, 75, 80, 90, 100], []);
  const [filteredPercentages, setFilteredPercentages] = React.useState<number[]>(percentages)

  const numberOfXPulls = React.useMemo(() => [3, 5, 10, 20, 30], []);
  const [filterednumberOfXPulls, setfilterednumberOfXPulls] = React.useState<number[]>(numberOfXPulls)

  const [phases, setPhases] = React.useState<string[]>([])

  const filteredPulls = pulls?.filter((pull) => {
    const matchesPhase = phase === "" || pull.phases.hasOwnProperty(phase);
    return matchesPhase
  });

  useEffect(() => {
    if (restrictSession) return;
      onSessionsSelectChange(selectedSessions)
  }, [onSessionsSelectChange, restrictSession,selectedSessions])

  useEffect(() => {
    setPercentage(percentage)
    onPercentageChange(percentage)
  }, [percentage, onPercentageChange])

  useEffect(() => {
    setLastXPulls(lastXPulls)
    onLastXPullsChange(lastXPulls)
  }, [lastXPulls, onLastXPullsChange])

  useEffect(() => {
    setPhase(phase)
    const maxPercentage = filteredPulls?.reduce((acc, cur) => {
      return Math.max(acc, cur.boss_hp)
    }, 0);
    const filteredPercentages = percentages?.filter((percent) => {
      return !(maxPercentage === undefined) ? percent <= maxPercentage : true
    });
    if (filteredPulls !== undefined) {
      const filteredXPulls = numberOfXPulls?.filter((number) => {
        return !(filteredPulls.length === undefined) ? number <= filteredPulls.length : true
      });
      setfilterednumberOfXPulls(filteredXPulls)
    }
    setFilteredPercentages(filteredPercentages)
    onPhaseChange(phase)
  }, [phase, filteredPulls, onPhaseChange, numberOfXPulls, percentages])

  useEffect(() => {
    setCm(cm)
    if (cm === "CM") {
      onCMChange(true)
    }
    else {
      onCMChange(false)
    }
  }, [cm, onCMChange])

  useEffect(() => {
    setNumberOfPlayers(NumberOfPlayers)
    onNumberOfPlayersChange(NumberOfPlayers)
  }, [NumberOfPlayers, onNumberOfPlayersChange])

  useEffect(() => {
    onCompChange(comp)
    const compPulls = pulls?.filter((pull) => {
      const matchesComp = comp.length === 0 || pull.comp ? comp.includes(pull.comp) : false;
      return matchesComp
    });
  
    const phases = [...new Set(compPulls?.flatMap(pull => Object.keys(pull.phases)))];
    if (phases.length > 0 && phases.indexOf("fullFight") !== -1) {
      phases.splice(phases.indexOf("fullFight"), 1);
      phases.unshift("fullFight");
    }
    setPhases(phases)
    
    if (phases.length > 0 && !phases.includes(phase)) {
      setPhase(phases[0])
    }
  }, [comp, onCompChange, pulls, phase])

  useEffect(() => {
    setComp([...new Set(pulls?.map(pull => pull.comp))])
  }, [pulls])

  // const handleDateRangeSelect = (start_date: Date, end_date: Date) => {
  //   setStartDate(start_date)
  //   setEndDate(end_date)
  //   onDateRangeChange({start_date, end_date})
  // }
  
  const phaseNames = Object.fromEntries(phases.map(phase => [phase, renamePhase(phase)]));
  const playerNumbers = Array(10).fill(0).map((_, i) => i + 1);

  if (phases.length > 0 && phase === "") {
    setPhase(phases[0])
  }

  const compnames = [...new Set(pulls?.map(pull => pull.comp))] || [];
  const compnamesToDict: Record<string, number> = compnames.reduce((acc, element, index) => {
    acc[element] = index;
    return acc;
  }, {} as Record<string, number>);

  return (
    <Tile>
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="icontooltip">
            Infos zur Gilde.
          </Tooltip>
        }
      >
        <div style={{ float: "right" }}>
          <TbInfoSquare></TbInfoSquare>
        </div>
      </OverlayTrigger>
      <Stack spacing={2} direction="row" alignItems="center">
        {bossData?.fight_icon && <img src={bossData?.fight_icon} alt="Boss Icon" style={{ width: '64px', height: '64px' }} />}
        < Grid style={{ paddingRight: '4px' }}>
            <div style={{ fontSize: '25px'}}>{bossData?.name}</div>
          {filteredPulls?.length !== 0 && pulls?.length !== 0 ? <div style={{ fontSize: '20px' }}>{filteredPulls ? filteredPulls.length : 0}/{pulls ? pulls.length : 0} Pulls</div> :
          pulls?.length !== 0 && <div style={{ fontSize: '20px' }}>{pulls ? pulls.length : 0} Pulls</div>}
        </Grid>        
        {restrictSession || !isSessionOrProject ?
          null
          :          
        // (<Grid item>
        //   <InputLabel id="date-range-select-label">Start and End Date</InputLabel>
        //   <LocalizationProvider dateAdapter={AdapterDayjs}>
        //     <DatePicker
        //       //label="Start Date"
        //       defaultValue={dayjs(start_date)}
        //       format="DD.MM.YYYY"
        //       onChange={(value) => setStartDate(value?.toDate() || new Date())} />
        //     <DatePicker
        //       format="DD.MM.YYYY"
        //       defaultValue={dayjs(end_date)}
        //       onChange={(value) => setEndDate(value?.toDate() || new Date())} />
        //   </LocalizationProvider>
          // </Grid>)

            <Grid item>
            <FormControl fullWidth style={{minWidth: 110, maxWidth: 110}}>
              <InputLabel id="sessions-select-label">Sessions</InputLabel>
                <Select 
                  labelId="sessions-select-label"
                  label="Sessions"
                  defaultValue={selectedSessions}
                  value={selectedSessions}
                  onChange={(e) => setSelectedSessions(e.target.value as unknown as string[])}
                  multiple
                  > 
                  {sessions?.map( session_obj => (
                    <MenuItem value={session_obj.id}>{session_obj.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
       }
        
        <Grid item>
        <FormControl fullWidth style={{minWidth: 100, maxWidth: 120}}>
          <InputLabel id="pulltype-select-label">Mode</InputLabel>
            <Select 
              label="Mode"
            labelId="pulltype-select-label"
              defaultValue={cm}
              value={cm}
            onChange={(e) => setCm(e.target.value as PullMode)}
            >
              <MenuItem value={"Normal"}>Normal</MenuItem>
              <MenuItem value={"CM"}>CM</MenuItem>
            </Select>
        </FormControl>
        </Grid>

        <Grid item>
        <FormControl fullWidth style={{minWidth: 100, maxWidth: 100}}>
          <InputLabel id="numberOfPulls-select-label">Last Pulls</InputLabel>
            <Select 
              label="Last Pulls"
              labelId="pulltype-select-label"
              defaultValue={lastXPulls}
              onChange={(e) => setLastXPulls(e.target.value as number)}
              > 
                <MenuItem value={-1}>All</MenuItem>
              {filterednumberOfXPulls.map((number) => (
                <MenuItem value={number}>{number}</MenuItem>
              ))}
            </Select>
        </FormControl>
        </Grid>

        <Grid item>
        <FormControl fullWidth style={{minWidth: 135, maxWidth: 150}}>
          <InputLabel id="charttype-select-label">Number of Players</InputLabel>
          <Select 
              labelId="charttype-select-label"
              label="Number of Players"
              defaultValue={NumberOfPlayers}
              value={NumberOfPlayers}
              onChange={(e) => setNumberOfPlayers(e.target.value as number)}
            >
              {playerNumbers?.map((number) => (
              <MenuItem value={number}>{number} Player(s)</MenuItem>
            ))}
            </Select>
        </FormControl>
        </Grid>

        <Grid item>
        <FormControl fullWidth style={{minWidth: 110, maxWidth: 110}}>
          <InputLabel id="teamcomp-select-label">Team Comp</InputLabel>
            <Select 
              labelId="teamcomp-select-label"
              label="Team Comp"
              value={comp}
              onChange={(e) => setComp(e.target.value as unknown as string[])}
              multiple
              > 
              {Object.entries(compnamesToDict).map(([comp, index]) => (
                <MenuItem value={comp}>Comp {index+1}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
        <FormControl fullWidth style={{minWidth: 110, maxWidth: 140}}>
          <InputLabel id="phase-select-label">Phase</InputLabel>
          <Select 
            labelId="phase-select-label"
            label="Phase"
              defaultValue={phase}
              value={phase}
            onChange={(e) => setPhase(e.target.value as string)}
            >
            {/* <MenuItem value={""}>All</MenuItem> */}
            {phases?.map((phase) => (
              <MenuItem value={phase}>{phaseNames[phase]}</MenuItem>
            ))}
            </Select>
        </FormControl>
        </Grid>

        <Grid item>
        <FormControl fullWidth style={{minWidth: 90, maxWidth: 90}}>
          <InputLabel id="percentage-select-label">Final HP</InputLabel>
            <Select 
              labelId="percentage-select-label"
              label="Final HP"
              defaultValue={percentage}
              onChange={(e) => setPercentage(e.target.value as number)}
              displayEmpty
              > 
                <MenuItem value={100.1}>All</MenuItem>
              {filteredPercentages.map((percent) => (
                <MenuItem value={percent}>{percent}%</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        
      </Stack>
    </Tile>
  );
};

export default FilterSessionPulls;
