interface ArrayElement {
    value: any;
    [key: string]: any; // Other attributes can be of any type
}

// Function to insert a value into a sorted array
export const insertIntoSortedArray = (arr: number[], value: number) => {
        const index = arr.findIndex((element) => element >= value);
    
        if (index === -1) {
          // If value is greater than all elements, add it to the end
          return [...arr, value];
        } else {
          // Otherwise, insert the value at the correct position
          return [...arr.slice(0, index), value, ...arr.slice(index)];
        }
    };
  
export const insertIntoSortedDict = (arr: ArrayElement[], insertDict: ArrayElement) => {
      const index = arr.findIndex((element) => element.value >= insertDict.value);
  
      if (index === -1) {
        // If value is greater than all elements, add it to the end
        return [...arr, insertDict];
      } else {
        // Otherwise, insert the value at the correct position
        return [...arr.slice(0, index), insertDict, ...arr.slice(index)];
      }
    }