import React from 'react';
import Plot from 'react-plotly.js';
import WarnAlert from '../alerts/WarnAlert';
import {generateVariationColor} from '../../utils/professions';
import { useTheme } from '@mui/material';
import { getPlotlyFontColor } from '../../theme/theme';

interface PlayerPullsStatsMaxMedianProps{
  players: Player[];
  professionMedian: ProfMedian;
  isMax: boolean;
}

type Player = {
  name: string;
  profession: string;
  maxDPS: number;
  medianDPS: number;
  endTime: Date;
  link: string;
}

type ProfMedian = {
  [profession: string]: number;
}

const PlayerPullsStatsMaxMedian: React.FC<PlayerPullsStatsMaxMedianProps> = ({ professionMedian, players, isMax }) => {
  const theme = useTheme();
  const fontColor = getPlotlyFontColor(theme.palette.mode);
  if (!professionMedian) {
    return <WarnAlert> No pulls to render.</WarnAlert>
  }
      
  const handleClick = (event: any) => {
    const clickedPoint = event.points[0].curveNumber;
    if (clickedPoint > players.length) {
      return
    }
      const clickedData = players[clickedPoint];
      if (clickedData) {
          const href = clickedData.link;
          window.open(href, '_blank', 'noreferrer');
      }
    };
    
  let plotArrays = [];

  if (isMax) {
    players.sort((a, b) => {return b.maxDPS - a.maxDPS || a.profession.localeCompare(b.profession)});
  }

  for (const player of players) {
    const color = generateVariationColor(player.profession);
    const data: Plotly.Data =
    {
      x: [player.name + " (" + player.profession + ")"],
      y: isMax ? [player.maxDPS] : [player.medianDPS],
      type: 'bar',
      marker: {
        color:color, // Use the specified color or default to 'blue'
      },
      name: player.name + " (" + player.profession + ")",
      hoverinfo: 'y+text',
      text: player.name
    };
    plotArrays.push(data);
  }

  for (const profession in professionMedian) {
    const color = generateVariationColor(profession);
    const medianData: Plotly.Data =
    {
      x: players.map(player => player.name + " (" + player.profession + ")"),
      y: Array(players.length).fill(professionMedian[profession]),
      type: 'scatter',
      mode: 'lines',
      marker: { color: color },
      name: profession + " Median",
      hoverinfo: 'y+text',
      text: profession,
    };
    plotArrays.push(medianData);
  }
    
    // const medianData: Plotly.Data =
    // {
    //   x: [player.name],
    //   y: [player.medianDPS],
    //   type: 'scatter',
    //   mode: 'lines',
    //   marker: { color: 'red' },
    //   name: player.name + "-" + player.profession,
    // };

    
    // plotArrays.push(medianData);
  

    const layout:any = {
      title: isMax ? "Max DPS per Player" : "Median DPS per Player",
      xaxis: {
        title: 'Player',
        showticklabels: false,
      },
      yaxis: {
        title: 'DPS',
      },
      margin: { t: 30, b: 100, l: 50, r: 25 },
      plot_bgcolor: 'rgba(0,0,0,0)',
      paper_bgcolor: 'rgba(0,0,0,0)',
      legend: {
        orientation: 'h',
        x: 0.5,
        y: -0.2,
        xanchor: 'center',
        yanchor: 'top',
        traceorder: 'normal',
    },
    font: {
      color: fontColor
    },
    height: 450,
        
    };

  if (isMax)
  {
    return < Plot data = { plotArrays } layout = { layout } onClick = {(event) => handleClick(event)} style = {{ width: '100%', height: '100%' }} useResizeHandler = { true} />;
    
  }
  else {
    
  return < Plot data = { plotArrays } layout = { layout } style = {{ width: '100%', height: '100%' }} useResizeHandler = { true} />;
  }
};

export default PlayerPullsStatsMaxMedian;
