import { createTheme, PaletteMode } from "@mui/material";
import React from "react";
import theme from "./theme";

export const useColorTheme = () => {
    const [mode, setMode] = React.useState<PaletteMode>("dark");

  const toggleColorMode = () =>{
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  }
    

  const modifiedTheme = React.useMemo(
    () =>
      createTheme({
        ...theme,
        palette: {
          ...theme.palette,
          mode,
          },
          typography: {
            // In Chinese and Japanese the characters are usually larger,
            // so a smaller fontsize may be appropriate.
            fontSize: 15,
          },
      }),
    [mode]
  );

//   const modifiedTheme = React.useMemo(
//     () => createTheme(getDesignTokens(mode)),
//     [mode]
//   );

  return {
    theme: modifiedTheme,
    mode,
    toggleColorMode,
  };
};