import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Pull } from '../../utils/pulls';
import ErrorAlert from '../alerts/ErrorAlert';
import { insertIntoSortedArray } from '../../utils/sorted_insert';

interface PlayerBoonTableProps {
    filteredPulls:  Pull[] | undefined;
    phaseName: string;
}

interface PlayerBoonData {
    [name: string]: {
        [boon: string]: number[];
    }
}

interface PlayerBoons {
    name: string;
    boons: Record<string, number>;
}

const PlayerBoonTable: React.FC<PlayerBoonTableProps> = ({ filteredPulls, phaseName }) => {
    
    if (!filteredPulls) {
        return <ErrorAlert> No pulls found.</ErrorAlert>;
    }

    const getPlayerBoonData = (filteredPulls: Pull[], phaseName: string) => {
        
        const playerdata: PlayerBoonData = {};
        
        for (const pull of filteredPulls) {

            for (const player in pull.players) {
                const boons = pull.players[player].buff_uptime
                const profession = pull.players[player].profession
                const tableName = player + " (" + profession + ")"

                

                if (!playerdata[tableName]) {
                    playerdata[tableName] = {}
                }

                for (const boon in boons) {
                    if (!boons[boon][phaseName]) {
                        continue;
                    }

                   if (!playerdata[tableName][boon]) {
                       playerdata[tableName][boon] = [boons[boon][phaseName].uptime]
                    }
                   else {
                    playerdata[tableName][boon] = insertIntoSortedArray(playerdata[tableName][boon], boons[boon][phaseName].uptime)
                    }                    
                }
            }
        }
        
        const output: PlayerBoons[] = []
        for (const player in playerdata) {
            const playerBoon: PlayerBoons = {
                name: player,
                boons: {}
            }

            for (const boon in playerdata[player]) {
                const middleIndex = Math.floor(playerdata[player][boon].length / 2);
                if (playerdata[player][boon].length % 2 !== 0) {
                    playerBoon.boons[boon] = playerdata[player][boon][middleIndex];
                } else {
                    // Array has an even number of elements, calculate the average of the two middle elements
                    const middleElement1 = playerdata[player][boon][middleIndex - 1];
                    const middleElement2 = playerdata[player][boon][middleIndex];
                    playerBoon.boons[boon] = Math.round(((middleElement1 + middleElement2) / 2)*10)/10;
                }
            }
            output.push(playerBoon)
        }

        return output;
    }

    const boondata = getPlayerBoonData(filteredPulls, phaseName);

    const getBoonNames = (PlayerBoonData: PlayerBoons[]) => {
        const boons = []
        
        for (const player in PlayerBoonData) {
            for (const boon in PlayerBoonData[player].boons) {
                boons.push(boon)
            }
        }
        
        const uniqueBoons = [...new Set(boons)]
        uniqueBoons.sort()
        
        return uniqueBoons
    }

    const boonNames = getBoonNames(boondata)

    return (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell align="left">Players</TableCell>
                {boonNames.map((boon) => (
                    <TableCell align="right">{boon}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {boondata.map(({ name, boons }) => (
                <TableRow
                  key={name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        {name}
                    </TableCell>
                      {boonNames.map((boon) => (
                          boons[boon] ? <TableCell align="right">{boon === "Might" ? boons[boon]:boons[boon]+"%"}</TableCell> : <TableCell align="right">{boon === "Might" ? "0":"0%"}</TableCell>
                    ))}
                      
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

export default PlayerBoonTable;