import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type Boss = {
  /**
   * Incident id
   */
  id: number;
  /**
   * Boss name
   */
  name: string;
  /**
     * url of the icon
     */
  fight_icon: string;
    /**
     * 
    */
    hp: number;
};

export const useBosses = () => {
    return useQuery<Boss[]>(
        ["bosses"],
        () => {
            return axios.get("/bosses/get-all/").then((e) => e.data);
        },
        { refetchInterval: 10000 /* 10s */ }
    );
};

export const useBoss = (id: string) => {
    let boss_id: number;
    if (!id) {
        boss_id = -1;
    }
    else {
        boss_id = parseInt(id);
    }
    return useQuery<Boss>(
        ["boss", id],
        () => {
            return axios.get(`/bosses?boss_id=${boss_id}`).then((e) => e.data);
        }
    );
}

