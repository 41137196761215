import { Container } from "react-bootstrap";
import ProjectOverview from "../components/tiles/ProjectOverview";

const Sessions = () => {
    return (
        <Container className="mt-4">
            <ProjectOverview></ProjectOverview>
        </Container >
    );
}

export default Sessions;