import { useEffect } from "react"
import { MdDarkMode, MdLightMode } from "react-icons/md"
import styled from "styled-components"

import { useThemeContext } from "../theme/ThemeContextProvider";

const DarkIcon = styled(MdDarkMode)`
    :hover {
        cursor: pointer
    }
`

const LightIcon = styled(MdLightMode)`
    :hover {
        cursor: pointer
    }
`

const Colormode = ({ iconDark = true }: { iconDark: boolean }) => {
    const { mode, toggleColorMode } = useThemeContext();

    const updateColor = () => {
        toggleColorMode()
    }

    useEffect(() => {
        // if (mode === "dark") enableDarkMode({ brightness: 100, contrast: 100, sepia: 0, styleSystemControls:false}); else disableDarkMode();
        // localStorage.setItem("mode", mode);
    }, [mode])

    return <>{mode === "dark" ?
        <DarkIcon size={"1.5em"} className={iconDark ? "text-dark" : "text-white"} onClick={updateColor} />
        : <LightIcon size={"1.5em"} className={iconDark ? "text-dark" : "text-white"} onClick={updateColor} />}</>
}

export default Colormode;