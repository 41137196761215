import Tile from "../Tile";
import { Session, useAcountSessions } from "../../utils/sessions";
import { useEffect, useState } from "react";
import { Autocomplete, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useGuildMembers } from "../../utils/guilds";
import InfoIcon from '@mui/icons-material/Info';

const SessionParticipation = () => {
  const [searchID, setsearchID] = useState<string>("");
  const [sortedSessions, setSortedSessions] = useState<Session[]>([]);
  const { data: sessions, isSuccess, isError } = useAcountSessions(searchID);
  const { data: members, isLoading:membersLoading } = useGuildMembers();

  const handleButtonClick = (value : string) => {
    if (members === undefined || members?.length === 0) return;
    for (let i = 0; i < members.length; i++) {
      if (members[i].account_name === value) {
        setsearchID(members[i].discord_id);
        return;
      }
    }
  }

  useEffect(() => {
    setSortedSessions(sessions? sessions.sort((a, b) => new Date(b.last_update).getTime() - new Date(a.last_update).getTime()) : []);
  }, [sessions]);

  

  return (
    <Tile style={{ overflow: "auto" }}>
      
      <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
      >
        <Grid item xs={12} sm container paddingBottom={2}>
          <Grid item xs container direction="column">
            Participation
          </Grid>

          <Grid item>
            <Tooltip title="Shows all sessions for the selected user. At least one GW2 account must be linked in order to find sessions." arrow placement="top-end">
              <InfoIcon />
                </Tooltip>
              </Grid>
          </Grid>
        </Typography>
       
      <Grid container paddingBottom={2} xs={6}>
      <Grid item xs container direction="column">
        <Autocomplete
          id="free-solo-playername"
          freeSolo
          options={members ? members.map((option) => option.account_name) : []}
          renderInput={(params) => <TextField error={isError && searchID !== ""} helperText={isError && searchID !== "" ? 'No Sessions found' : null} {...params} label="Discord Name" />}
          loading={membersLoading}
          onInputChange={(event, newInputValue) => newInputValue !== null ? handleButtonClick(newInputValue) : null}
          onChange={(event, newInputValue) => newInputValue !== null ? handleButtonClick(newInputValue) : null}
          />
          </Grid>
      </Grid>
      <Table className="table justify-content-between">
        <TableHead>
          <TableRow>
            <TableCell scope="col"><div style={{ display: 'flex', alignItems: 'center' }}> <span style={{ marginRight: '8px' }}>Session </span> </div></TableCell>
            <TableCell scope="col"><div style={{ display: 'flex',alignItems: 'center' }}> <span style={{ marginRight: '8px' }}>Last Update </span></div></TableCell>
            <TableCell scope="col"><div style={{ display: 'flex',alignItems: 'center' }}> <span style={{ marginRight: '8px' }}>Page </span></div></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isSuccess && sortedSessions.map(session => (
            <TableRow key={session.id} style={{ cursor: "pointer" }}>
              <TableCell  >{session.name}</TableCell >
              <TableCell  >{new Date(session.last_update).toLocaleString()}</TableCell >
              <TableCell ><Link to={`/sessions/${session.id}`} target="_blank"> Session Page</Link></TableCell >
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Tile>
  );
};

export default SessionParticipation;
