import { useParams } from "react-router-dom";
import ErrorAlert from "../components/alerts/ErrorAlert";
import LoadingTile from "../components/tiles/LoadingTile";
import { tiles } from "../utils/tile";
import { Suspense } from "react";
import Loading from "../components/Loading";
import TilesLayout from "../components/TilesLayout";
import SessionInfo from "../components/tiles/SessionInfo";
import PullOverview from "../components/tiles/PullOverview";
import PullBossOverview from "../components/tiles/PullBossOverview";
import { usePullsProjectData } from "../utils/pulls";
import { useProject } from "../utils/projects";

const Project = () => {
  const { id } = useParams();
  const { data: project, isLoading, isError } = useProject(id as string)
  
  // const { data: pulls, isLoading: isLoadingPulls, isError: isErrorPulls } = usePullsData(id as string)

  if (isLoading) return <LoadingTile />;

  if (isError)
    return <ErrorAlert> Session nicht gefunden.</ErrorAlert>;

  const { defaultTiles, defaultLayout } = tiles([
    { el: <SessionInfo session={project} />, id: "a", name: "Guild Info", main: { x: 0, y: 0, w: 8, h: 3 }, mobile: { x: 0, y: 0, w: 24, h: 3 } },
    // { el: <LogCount session_id={project.id} usePullsFunc={usePullsProjectData}/>, id: "b", name: "Logs", main: { x: 8, y: 0, w: 8, h: 3 }, mobile: { x: 0, y: 3, w: 24, h: 3 } },
    // { el: <GuildInfo guild_id={project.guild_id} />, id: "c", name: "Project Info", main: { x: 16, y: 0, w: 8, h: 3 }, mobile: { x: 0, y: 0, w: 24, h: 3 } },

    { el: <PullBossOverview isSession={false} usePullsFunc={usePullsProjectData}/>, id: "d", name: "Pull Boss Overview", main: { x: 0, y: 4, w: 8, h: 17 }, mobile: { x: 0, y: 29, w: 24, h: 12 } },
    { el: <PullOverview isSession={false} usePullsFunc={usePullsProjectData}/>, id: "e", name: "Pull Overview", main: { x: 9, y: 4, w: 16, h: 20 }, mobile: { x: 0, y: 29, w: 24, h: 12 } },])

  return (
    <div className="App_">
      <Suspense fallback={<Loading />}>
        <TilesLayout layoutId="project-v1" defaultLayout={defaultLayout} defaultTiles={defaultTiles} />
      </Suspense>
    </div>
  )
}

export default Project