import React from 'react';
import { Pull } from '../../utils/pulls';
import ErrorAlert from '../alerts/ErrorAlert';
import { Grid } from '@mui/material';
import PlayerBoonTable from './PlayerBoonOverview';

interface PullPhaseTimesProps{
  filteredPulls: Pull[] | undefined;
  phaseName: string;
  record: Pull | undefined;
}

const PlayerBuffStats: React.FC<PullPhaseTimesProps> = ({ filteredPulls, phaseName, record }) => {
  const [boon, setBoon] = React.useState<string>('');
  // const [player, setPlayer] = React.useState<string>('');  

  if (!filteredPulls) {
    return <ErrorAlert> No pulls found.</ErrorAlert>;
  }
  if (!phaseName) {
    return <ErrorAlert> No phase selected.</ErrorAlert>;
  }
  
  const boons = [...new Set(filteredPulls.map(pull => Object.keys(pull.players).map(player => Object.keys(pull.players[player].buff_uptime)).flat()).flat())];
  boons.sort();

  // const players = [...new Set(filteredPulls.map(pull => Object.keys(pull.players).map(player => player)).flat())];

  if (boon === '' && boons.length > 0) {
    setBoon(boons[0]);
  }

  return (
    <Grid container item xs={12} spacing={1} style={{ height: '40%' }}>
      {/* <Grid item >
        <InputLabel id="boon-select-label">Boon</InputLabel>
          <Select 
            labelId="boon-select-label"
            defaultValue={boon}
            value={boon}
            onChange={(e) => setBoon(e.target.value as string)}
            >
              {boons?.map((curr_boon) => (
              <MenuItem value={curr_boon}>{curr_boon}</MenuItem>
            ))}
        </Select>
        <InputLabel id="player-select-label">Boon</InputLabel>
          <Select 
            labelId="player-select-label"
            defaultValue={player}
            value={player}
          onChange={(e) => setPlayer(e.target.value as string)}
          displayEmpty
        > 
              <MenuItem value="">All</MenuItem>
              {players?.map((curr_player) => (
              <MenuItem value={curr_player}>{curr_player}</MenuItem>
            ))}
          </Select>
      </Grid> */}
      
      <Grid item xs={12} md={12} height={500}>
          <PlayerBoonTable filteredPulls={filteredPulls} phaseName={phaseName}/>
      </Grid>
    </Grid>
  );
};

export default PlayerBuffStats;
