import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { oauthRedirectUrl} from "../config/config";

export type OauthToken = {
  access_token: string;
  token_type: string;
};


export const useDiscordOauth = (code: string) => {
  return useQuery<OauthToken>(
    ["oauth", code, oauthRedirectUrl],
    () => {
      return axios.get(`/oauth/callback?code=${encodeURIComponent(code)}&redirect_uri=${encodeURIComponent(oauthRedirectUrl)}`).then((e) => e.data);
    },
  );
};
