import { Card, CardContent, CardHeader } from "@mui/material"


type PanelProps = {
    children?: React.ReactNode,
    style?: React.CSSProperties,
    classes?: string
    name?: string
}

/* Tile with body only (includes padding) */
const Tile = (props: PanelProps) => {
    return <Card style={{ ...props.style, ...{ width: "100%", height: "100%" } }} className={`my-1 shadow-sm border-0`} >
        {props.name ? <CardHeader className="text-center">{props.name}</CardHeader> : null}
        <CardContent className={`${props.classes}`}>
            {props.children}
        </CardContent>
    </Card>
}

export default Tile;