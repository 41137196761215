import Image1 from "../assets/img/loading/LoadingImage1.webp";
import Image2 from "../assets/img/loading/LoadingImage2.webp";
import Image12 from "../assets/img/loading/LoadingImage12.webp";
import Image13 from "../assets/img/loading/LoadingImage13.webp";
import Image14 from "../assets/img/loading/LoadingImage14.webp";
import Image15 from "../assets/img/loading/LoadingImage15.webp";
import Image16 from "../assets/img/loading/LoadingImage16.webp";
import Image17 from "../assets/img/loading/LoadingImage17.webp";
import Image18 from "../assets/img/loading/LoadingImage18.webp";
import Image19 from "../assets/img/loading/LoadingImage19.webp";
import Image44 from "../assets/img/loading/LoadingImage44.webp";
import Image45 from "../assets/img/loading/LoadingImage45.webp";
import Image46 from "../assets/img/loading/LoadingImage46.webp";
import Image47 from "../assets/img/loading/LoadingImage47.webp";
import Image48 from "../assets/img/loading/LoadingImage48.webp";
import Image49 from "../assets/img/loading/LoadingImage49.webp";
import Image50 from "../assets/img/loading/LoadingImage50.webp";
import Image51 from "../assets/img/loading/LoadingImage51.webp";
import Image52 from "../assets/img/loading/LoadingImage52.webp";
import Image53 from "../assets/img/loading/LoadingImage53.webp";


export const loadingImages = [
    Image1,
    Image2,
    Image12,
    Image13,
    Image14,
    Image15,
    Image16,
    Image17,
    Image18,
    Image19,
    Image44,
    Image45,
    Image46,
    Image47,
    Image48,
    Image49,
    Image50,
    Image51,
    Image52,
    Image53,
];