import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { useReport } from "../utils/reports";
import LoadingTile from "../components/tiles/LoadingTile";
import ErrorAlert from "../components/alerts/ErrorAlert";

function Report() {
    const { id } = useParams();    
    const { data, isLoading, isError } = useReport(id as string)
  return (
    <Grid container style={{
      minWidth: "100%",
      height: "100vh",
    }}>
      
          <div style={{ width: '100%', height: '100%', background: '#272b30', margin: 0, padding: 0, overflow: 'hidden' }}>
        {isLoading && (
          // Show loading element while HTML content is loading
          <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <LoadingTile />
            
        </div>
        )}
        {isError && (
          // Show error message if HTML content could not be loaded
          <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ErrorAlert > Report doesnt exist.</ErrorAlert>
        </div>
        )}
        {!isLoading && data !== undefined && (
          // Render iframe with HTML content after it is loaded
          <iframe
            title="HTML Content"
            srcDoc={data.html}
            style={{ width: '100%', height: '100%', border: 'none'}}
          />
          
        )}
        </div>
    </Grid>

    );
}

export default Report;