import { useParams } from "react-router-dom";
import LoadingTile from "../components/tiles/LoadingTile";
import { useEffect, useState } from "react";
import { Pull, usePullsRecord} from "../utils/pulls";
import FilterSessionPulls from "../components/tiles/FilterSessionPulls";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import PhaseTimesTrendPlot from "../components/plots/PhaseTimes";
import Tile from "../components/Tile";
import PullStats from "../components/plots/PullStats";
import PullPhaseDurationsPlot from "../components/plots/PullPhaseDurations";
import 'react-tabs/style/react-tabs.css';
import PlayerPullsStats from "../components/plots/PlayerPullsStats";
import PlayerBuffStats from "../components/plots/PlayerBuffStats";
import ErrorAlert from "../components/alerts/ErrorAlert";
import { UseQueryResult } from "@tanstack/react-query";
import { useAuth } from "../service/auth";
import {  apiRootWebsocket } from "../config/config";
import useWebSocket from "react-use-websocket";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PullAnalysis = (props:{usePullsFunc: (ids: any, urlBossId: string, cm: boolean, numberOfPlayers: number) => UseQueryResult<Pull[], unknown>, isSession: boolean}) => {
  const { bossId: urlBossId, isCm: urlIsCm, sessionIds: urlIds } = useParams();
  const token = useAuth().token
  const [tab, setTab] = useState<number>(0)
  const [barChartTab, setBarChartTab] = useState<number>(0)
  const [plotByDate, setPlotByDate] = useState<number>(0)
  const [comp , setComp] = useState<string[]>([])
  
  const [lastXPulls, setLastXPulls] = useState<number>(-1)
  const [phase, setPhase] = useState<string>("")
  const [percentage, setPercentage] = useState<number>(80)
  const [cm, setCm] = useState<boolean>(urlIsCm === "true" ? true : false)
  const [NumberOfPlayers, setNumberOfPlayers] = useState<number>(10)
  const [selectedIds, setselectedIds] = useState<string[]>(urlIds !== undefined ? urlIds.split('+') : [])
  const [data, setData, ] = useState<Pull[]>([])
  const socketUrl = `${apiRootWebsocket}session/boss/pulls/ws`

  const { data: initialPulls, isLoading, isError } = props.usePullsFunc(selectedIds, urlBossId as string, cm, NumberOfPlayers as number)
  
  const { lastJsonMessage, 
  } = useWebSocket(socketUrl,
    {
      queryParams: { "boss_id": urlBossId as string, "is_cm": cm ? "true" :"false", "number_of_players": NumberOfPlayers as number, "session_ids": selectedIds.join(",") },
      protocols: token ? token : "",
    }, true);

  useEffect(() => {
    if (lastJsonMessage === null || lastJsonMessage === undefined) {
      setData(initialPulls ? initialPulls : [])
    }
    else {
      const newPull: Pull = lastJsonMessage.pull
      const action = lastJsonMessage.type

      if (action === "add") {
        if (initialPulls? initialPulls.find((pull) => pull.id === newPull.id) : false) {
          setData(initialPulls ? initialPulls : [])
          return
        }

        setData(initialPulls? [...initialPulls, newPull] : [newPull])
      }
      else if (action === "remove") {
        setData(initialPulls?.filter((pull) => pull.id !== newPull.id) || [])
      }
    }
    }
  , [lastJsonMessage, initialPulls])

  const { data: record } = usePullsRecord(urlBossId as string, cm, NumberOfPlayers);

  const onSessionsSelectChange = (sessionIds: string[]) => {
    setselectedIds(sessionIds)
  }

  const handlePercentageChange = (percentage: number) => {
    setPercentage(percentage)
  }

  const handlePhaseChange = (phase: string) => {
    setPhase(phase)
  }

  const handleCmChange = (cm: boolean) => {
    setCm(cm)
  }

  const handleNumberOfPlayersChange = (NumberOfPlayers: number) => {
    setNumberOfPlayers(NumberOfPlayers)
  }

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const handleBarChartTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setBarChartTab(newTab);
  }

  const handlePlotByDateChange = (event: React.SyntheticEvent, newTab: number) => {
    setPlotByDate(newTab)
  }

  const handleCompChange = (comp: string[]) => {
    setComp(comp)
  }

  const handeLastXPulls = (lastXPulls: number) => {
    setLastXPulls(lastXPulls)
  }
  const compPercentagePulls = data?.filter((pull) => {
    const matchesComp = comp.length === 0 || pull.comp ? comp.includes(pull.comp) : false;
    const matchesPercentage = pull.boss_hp <= percentage
    return matchesPercentage && matchesComp
  }) || [];

  const prefilteredPulls = compPercentagePulls?.filter((pull) => {
    const matchesPhase = phase === "" || pull.phases.hasOwnProperty(phase);
    return matchesPhase
  }) || [];

  let filteredPulls = prefilteredPulls
  if (lastXPulls > 0){
    filteredPulls = prefilteredPulls.slice(-lastXPulls)
  }
  let filteredcompPercentagePulls = compPercentagePulls
  if (lastXPulls > 0){
    filteredcompPercentagePulls = compPercentagePulls.slice(-lastXPulls)
  }

  const sumOfPhases = filteredPulls.map((pull) => {
    let sum = 0;
    for (const [key, value] of Object.entries(pull.phases)) {
      if (["fullfight", "kills", "kill"].includes(key.toLowerCase().replace(" ", ""))) {
        continue;
      }
      sum += value.duration;
      if (key === phase) {
        break;
      }
    }
    sum = Math.round((sum + Number.EPSILON) * 1000) / 1000
    return { ...pull, sum };
  }, []);

  
  let phaseDict: Record<string, any> = {};
  filteredcompPercentagePulls.forEach((pull) => {
    for (const [key, value] of Object.entries(pull.phases)) {
      if (["fullfight", "kills", "kill"].includes(key.toLowerCase().replace(" ", ""))) {
        continue;
      }
      const phase_key = key.toLowerCase().replaceAll(" ", "")
      if (phaseDict.hasOwnProperty(phase_key) === false || value.duration < phaseDict[phase_key]["duration"]) {
        phaseDict = { ...phaseDict, [phase_key]: {"name":key, "duration":value.duration, "log":pull.id} };
        if (key === phase) {
          break;
        }
      }
    }
  }, []);
  const bestSumPull = sumOfPhases.length > 0 ? sumOfPhases.reduce((prev, current) => (prev.sum < current.sum) ? prev : current) : null;

  // const { defaultTiles, defaultLayout } = tiles([
  //   { el: <FilterSessionPulls onPhaseChange={handlePhaseChange} onPercentageChange={handlePercentageChange} onDateRangeChange={handleDateRangeChange} onSessionIdsChange={handleSessionIdsChange} />, id: "a", name: "Filter", main: { x: 0, y: 0, w: 24, h: 3 }, mobile: { x: 0, y: 0, w: 24, h: 3 } },
  //   { el: <PhaseTimesTrendPlot filteredPulls={filteredPulls} phaseName={phase} />,enabled: true,  id: "b", name: "Trend", main: { x: 0, y: 8, w: 12, h: 3 }, mobile: { x: 0, y: 3, w: 12, h: 3 }},
  //  ])
  
  return (
    <Grid container sx={{ padding: "1rem" }}>
      <Grid container item xs={12} md={12} spacing={1} style={{ height: '20%' }}>
          <FilterSessionPulls isSessionOrProject={props.isSession} pulls={data} filterNumberOfPlayers={NumberOfPlayers} onSessionsSelectChange={onSessionsSelectChange} onLastXPullsChange={handeLastXPulls} onCompChange={handleCompChange} onCMChange={handleCmChange} onNumberOfPlayersChange={handleNumberOfPlayersChange} onPhaseChange={handlePhaseChange} onPercentageChange={handlePercentageChange}/>  
      </Grid>
      {(isLoading) ? (<LoadingTile />) :
        
        <Box sx={{ width: '100%' }}>
        
          {isError ? (<ErrorAlert> No Pulls found.</ErrorAlert>) :
            <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Phase Duration" {...a11yProps(0)} />
            <Tab label="Player DPS" {...a11yProps(1)} />
            <Tab label="Boon Uptime" {...a11yProps(2)} />
          </Tabs>
        </Box>
          
            <CustomTabPanel value={tab} index={0}>
            
              <Grid container item xs={12} spacing={1} height={550}>
                <Grid item xs={12} sm={12} md={6} height={550}>
                    <Tile>
                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={plotByDate} onChange={handlePlotByDateChange} aria-label="basic tabs example">
                          <Tab label="By Date" {...a11yProps(0)} />
                          <Tab label="By Pull" {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                      <CustomTabPanel value={plotByDate} index={0}>
                        <PhaseTimesTrendPlot filteredPulls={filteredPulls} phaseName={phase} record={record} plotByDate={true} />
                      </CustomTabPanel>
                      <CustomTabPanel value={plotByDate} index={1}>
                        <PhaseTimesTrendPlot filteredPulls={filteredPulls} phaseName={phase} record={record} plotByDate={false} />
                      </CustomTabPanel>
                      </Box>
                  </Tile>
                  </Grid>
                  <PullStats pull={bestSumPull} sumOfBest={phaseDict} phase={phase} onlyPull={false} />

                <Grid item xs={12} sm={12} md={6} height={550} >
                  <Tile>
                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={barChartTab} onChange={handleBarChartTabChange} aria-label="basic tabs example">
                          <Tab label="Bar Chart" {...a11yProps(0)} />
                          <Tab label="Line Graph" {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                      <CustomTabPanel value={barChartTab} index={0}>
                        <PullPhaseDurationsPlot filteredPulls={filteredPulls} phaseName={phase} calcSum={true} barChart={true} record={record} />
                      </CustomTabPanel>
                      <CustomTabPanel value={barChartTab} index={1}>
                        <PullPhaseDurationsPlot filteredPulls={filteredPulls} phaseName={phase} calcSum={true} barChart={false} record={record} />
                      </CustomTabPanel>
                    </Box>
                  </Tile>
                </Grid>
                <Grid item xs={12} sm={12} md={6} height={550}>
                  <Tile>
                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={barChartTab} onChange={handleBarChartTabChange} aria-label="basic tabs example">
                          <Tab label="Bar Chart" {...a11yProps(0)} />
                          <Tab label="Line Graph" {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                      <CustomTabPanel value={barChartTab} index={0}>
                        <PullPhaseDurationsPlot filteredPulls={filteredPulls} phaseName={phase} calcSum={false} barChart={true} record={record} />
                      </CustomTabPanel>
                      <CustomTabPanel value={barChartTab} index={1}>
                        <PullPhaseDurationsPlot filteredPulls={filteredPulls} phaseName={phase} calcSum={false} barChart={false} record={record} />
                      </CustomTabPanel>
                    </Box>
                  </Tile>
                </Grid>
            
                {/* <Grid item xs={12} md={6}>
            <Tile>
              {filteredPulls?.map((pull) => (
                  <InputLabel>{pull.fight_name} {pull.boss_hp}% {pull.duration}</InputLabel>
              ))}
              </Tile>
            </Grid> */}
              </Grid>
            </CustomTabPanel>
          
      <CustomTabPanel value={tab} index={1}>
        
            <PlayerPullsStats filteredPulls={filteredPulls} phaseName={phase} record={record} />
      </CustomTabPanel>
      
      <CustomTabPanel value={tab} index={2}>
        <PlayerBuffStats filteredPulls={filteredPulls} phaseName={phase} record={record} />
              </CustomTabPanel>
              </Box>}
      </Box>      
    }
      
    </Grid>      
  )
}

export default PullAnalysis