
export const professionColorsHex: any = {
    "Herald": "#D16E5A",
    "Vindicator": "#D16E5A",
    "Renegade": "#D16E5A",
    "Revenant": "#D16E5A",
    "Weaver": "#F68A87",
    "Tempest": "#F68A87",
    "Catalyst": "#F68A87",
    "Elementalist": "#F68A87",
    "Daredevil": "#C08F95",
    "Deadeye": "#C08F95",
    "Specter": "#C08F95",
    "Thief": "#C08F95",
    "Firebrand": "#72C1D9",
    "Dragonhunter": "#72C1D9",
    "Willbender": "#72C1D9",
    "Guardian": "#72C1D9",
    "Berserker": "#FFD166",
    "Bladesworn": "#FFD166",
    "Spellbreaker": "#FFD166",
    "Warrior": "#FFD166",
    "Scourge": "#428659",
    "Reaper": "#428659",
    "Harbinger": "#428659",
    "Necromancer": "#428659",
    "Chronomancer": "#B679D5",
    "Virtuoso": "#B679D5",
    "Mirage": "#B679D5",
    "Mesmer": "#B679D5",
    "Druid": "#8EDF44",
    "Soulbeast": "#8EDF44",
    "Untamed": "#8EDF44",
    "Ranger": "#8EDF44",
    "Holosmith": "#D09C59",
    "Scrapper": "#D09C59",
    "Mechanist": "#D09C59",
    "Engineer": "#D09C59",
}

export const professionColors: any = {
  "Herald": "rgb(209, 110, 90)",
  "Vindicator": "rgb(209, 110, 90)",
  "Renegade": "rgb(209, 110, 90)",
  "Revenant": "rgb(209, 110, 90)",
  "Weaver": "rgb(246, 138, 135)",
  "Tempest": "rgb(246, 138, 135)",
  "Catalyst": "rgb(246, 138, 135)",
  "Elementalist": "rgb(246, 138, 135)",
  "Daredevil": "rgb(192, 143, 149)",
  "Deadeye": "rgb(192, 143, 149)",
  "Specter": "rgb(192, 143, 149)",
  "Thief": "rgb(192, 143, 149)",
  "Firebrand": "rgb(114, 193, 217)",
  "Dragonhunter": "rgb(114, 193, 217)",
  "Willbender": "rgb(114, 193, 217)",
  "Guardian": "rgb(114, 193, 217)",
  "Berserker": "rgb(255, 209, 102)",
  "Bladesworn": "rgb(255, 209, 102)",
  "Spellbreaker": "rgb(255, 209, 102)",
  "Warrior": "rgb(255, 209, 102)",
  "Scourge": "rgb(66, 134, 89)",
  "Reaper": "rgb(66, 134, 89)",
  "Harbinger": "rgb(66, 134, 89)",
  "Necromancer": "rgb(66, 134, 89)",
  "Chronomancer": "rgb(182, 121, 213)",
  "Virtuoso": "rgb(182, 121, 213)",
  "Mirage": "rgb(182, 121, 213)",
  "Mesmer": "rgb(182, 121, 213)",
  "Druid": "rgb(142, 223, 68)",
  "Soulbeast": "rgb(142, 223, 68)",
  "Untamed": "rgb(142, 223, 68)",
  "Ranger": "rgb(142, 223, 68)",
  "Holosmith": "rgb(208, 156, 89)",
  "Scrapper": "rgb(208, 156, 89)",
  "Mechanist": "rgb(208, 156, 89)",
  "Engineer": "rgb(208, 156, 89)",
};

export function generateVariationColor(profession: string): string {
  const profarr = profession.split(" ");
  if (profarr.length === 0) return "#000000";

  const prof = profarr[profarr.length - 1];
  const baseColor = professionColors[prof];

  const rgb = baseColor.match(/\d+/g)!.map(Number);
  let adjustedRGB = rgb;
  if (profarr.length > 1) {
    const type = profarr[0];
    const percent = getShadePercentage(type);
    adjustedRGB = [
      clamp(Math.round(rgb[0] + percent), 0, 255),
      clamp(Math.round(rgb[1] + percent), 0, 255),
      clamp(Math.round(rgb[2] + percent), 0, 255),
    ];
  }

  return rgbToHex(adjustedRGB);
}

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

function getShadePercentage(type: string): number {
  switch (type) {
    case "Heal":
        return -30;
    case "Alac":
        return 10;
    case "Quick":
        return 30;
    default:
        return 0;
}
}

function rgbToHex(rgb: number[]): string {
  return `#${rgb.map(component => component.toString(16).padStart(2, '0')).join('')}`;
}