import React from 'react';
import Plot from 'react-plotly.js';
import { Pull } from '../../utils/pulls';
import WarnAlert from '../alerts/WarnAlert';
import { generateVariationColor } from '../../utils/professions';
import { useTheme } from '@mui/material';
import { getPlotlyFontColor } from '../../theme/theme';

interface SinglePlayerDpsAtSecProps{
  filteredPulls: Pull[] | undefined;
  phaseName: string;
  record: Pull | undefined;
}

type professionMedianCollection = {
  [profession: string]: {
  professionDPS: number[][];
  topPullDps: number;
  topLink: string;
    topDmgAtSec: number[];
    topIndex: number;
}
}

type professionStats = {
  name: string;
  professionMedian: number[];
  topPullDps: number[];
  topPullSec: number[];
  topLink: string;
}

const SinglePlayerDpsAtSec: React.FC<SinglePlayerDpsAtSecProps> = ({ filteredPulls, phaseName, record }) => {
  const theme = useTheme();
  const fontColor = getPlotlyFontColor(theme.palette.mode);
  
  if (!filteredPulls) {
    return <WarnAlert> No pulls to render.</WarnAlert>
  }

  const gatherDpsData = (dataArray: Pull[], phaseName: string): professionStats[] => {
    const professionMedian: professionMedianCollection = {};
    let longest = 0;
    let index = -1;
    for (const pull of dataArray) {
      index++;
      if (!pull.phases.hasOwnProperty(phaseName)) {
        continue;
      }

      for (const player of Object.values(pull.players)) {
        if (!professionMedian.hasOwnProperty(player.role)) {
          professionMedian[player.role] = {professionDPS: [], topPullDps: 0, topLink: "", topDmgAtSec: [], topIndex: 0};
        }
        
        professionMedian[player.role].professionDPS.push(player.dmg_at_second[phaseName]);
        
        if (player.dmg_at_second[phaseName].length > longest) {
          longest = player.dmg_at_second[phaseName].length;
        }

        if (professionMedian[player.role].topPullDps < player.dps[phaseName]) {
          professionMedian[player.role].topPullDps = player.dps[phaseName];
          professionMedian[player.role].topDmgAtSec = player.dmg_at_second[phaseName];
          professionMedian[player.role].topLink = pull.id;
          professionMedian[player.role].topIndex = index;
        }
        }
      }
    

    const seconds = Array.from({ length: longest }, (_, index) => index);

    const output: professionStats[] = [];

    for (const [professionName, profession] of Object.entries(professionMedian)) {
      const ProfessionMedian = seconds.map((second) => {
        const valuesAtSecond = profession.professionDPS.map((arr) => (second < arr.length ? second === 0? 0: arr[second] / second : null));
        const validValues = valuesAtSecond.filter((value): value is number => value !== null);
      
        if (validValues.length === 0) {
          return 0;
        }

        const sortedValues = validValues.sort((a, b) => a - b);
        const mid = Math.floor(sortedValues.length / 2);
        return sortedValues.length % 2 !== 0 ? sortedValues[mid] : (sortedValues[mid - 1] + sortedValues[mid]) / 2;
      });

      const bestPullSec = Array.from({ length: profession.topDmgAtSec.length }, (_, index) => index + 1 === profession.topDmgAtSec.length ? dataArray[profession.topIndex].phases[phaseName]["duration"] : index)
      const topDps = profession.topDmgAtSec.map((dmg, index) => bestPullSec[index] === 0 ? 0 : Math.round(dmg / bestPullSec[index]));
      
      output.push({
        name: professionName,
        professionMedian: ProfessionMedian,
        topPullDps: topDps,
        topPullSec: bestPullSec,
        topLink: profession.topLink
      });
    }
    return output;
  };
  

  const dpsData = gatherDpsData(filteredPulls, phaseName);

  const handleClick = (event: any) => {
    const clickedPoint = event.points[0];
    if (clickedPoint.data.text !== undefined) {
      window.open(clickedPoint.data.text, '_blank');
    }
    };
    
  let plotArrays: Plotly.Data[] = [];

  for (const professionData of dpsData) {
    
    const color = generateVariationColor(professionData.name);
    plotArrays.push(
      {
        y: professionData.professionMedian,
        x: Array.from({ length: professionData.professionMedian.length }, (_, index) => index),
        type: 'scatter',
        mode: 'lines',
        name: professionData.name + " Median",
        hoverinfo: 'x+y+name',
        marker: { color: color },
      });
    plotArrays.push(
      {
        y: professionData.topPullDps,
        x: professionData.topPullSec,
        type: 'scatter',
        mode: 'lines',
        marker: { color: color },
        name: professionData.name + " Pull",
        hoverinfo: 'x+y+name',
        text: professionData.topLink,
      });
  }
  const layout:any = {
    title: "Profession DPS per Second",
    xaxis: { title: 'Second'},
      yaxis: { title: 'DPS' },
      margin: { t: 30, b: 100, l: 50, r: 25 },
      plot_bgcolor: 'rgba(0,0,0,0)',
    paper_bgcolor: 'rgba(0,0,0,0)',
    legend: {
      orientation: 'h',
      x: 0.5,
      y: -0.3,
      xanchor: 'center',
      yanchor: 'top',
      traceorder: 'normal',
  },
  font: {
    color: fontColor
  }
        
    };
  return < Plot data = { plotArrays } onClick={(event) => handleClick(event)} layout = { layout } style = {{ width: '100%', height: '100%' }} useResizeHandler = { true} />
};

export default SinglePlayerDpsAtSec;
