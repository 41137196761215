import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import ErrorAlert from "../alerts/ErrorAlert";
import Tile from "../Tile";
import { TbInfoSquare } from "react-icons/tb";
import { Session } from "../../utils/sessions";
import { Project } from "../../utils/projects";

const SessionInfo = (props: {
  session: Session | Project;
}) => {
  
  if (props.session === undefined) return <ErrorAlert> No Data found.</ErrorAlert>;

  return (
    <Tile>
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="icontooltip">
            Infos zur Gilde.
          </Tooltip>
        }
      >
        <div style={{ float: "right" }}>
          <TbInfoSquare></TbInfoSquare>
        </div>
      </OverlayTrigger>
      <Card.Title className="text-center">{props.session.name}</Card.Title>
      <div>{new Date(props.session.last_update).toLocaleString()}</div>
    </Tile>
  );
};

export default SessionInfo;
