

import Tile from "../Tile";
import { useNavigate } from "react-router-dom";
import { useProjects, Project } from "../../utils/projects";
import LoadingTile from "./LoadingTile";
import ErrorAlert from "../alerts/ErrorAlert";
import { useEffect, useState } from "react";
import SortingArrow from "../SortingArrow";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";


const ProjectOverview = () => {
  const navigate = useNavigate()
  const [projects, setProjects] = useState<Project[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const { data, isLoading, isError, isSuccess } = useProjects()
  const [currentSort, setCurrentSort] = useState(1);

  const [sortingArrays, setSortingArrays] = useState([
    { name: "name", sortDirection: 2 },
    { name: "last_update", sortDirection: 2 },
  ]);

  useEffect(() => {
    if (isSuccess) {
    const sortedData = data.sort((a, b) => sortOrder === 'asc' ? new Date(a.last_update).getTime() - new Date(b.last_update).getTime() : new Date(b.last_update).getTime() - new Date(a.last_update).getTime());
    setProjects(sortedData);
    }
  }, [data, isSuccess, sortOrder]);

  if (isLoading) return <LoadingTile />

  if (isError) return <ErrorAlert> Couldnt load projects.</ErrorAlert>;

  let sortedData = [];
  if (currentSort === 0) {
    sortedData = projects.sort((a, b) => sortOrder === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name));
  } else {
    sortedData = projects.sort((a, b) => sortOrder === 'asc' ? new Date(a.last_update).getTime() - new Date(b.last_update).getTime() : new Date(b.last_update).getTime() - new Date(a.last_update).getTime());
  }
  
  const handleSortByProject = () => {
    resetArrows();
    handleSortChange("name", (sortOrder === 'asc' ? 0 : 1));
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setCurrentSort(0);
  };

  const handleSortByLastUpdate = () => {
    resetArrows();
    handleSortChange("last_update", (sortOrder === 'asc' ? 0 : 1));
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setCurrentSort(1);
  };

  const resetArrows = () => {
    handleSortChange("name", 2);
    handleSortChange("last_update", 2);
  }


  const handleSortChange = (arrayName: string, newDirection: any) => {
    const newArray = [...sortingArrays];
    const arrayIndex = newArray.findIndex(array => array.name === arrayName);
    newArray[arrayIndex].sortDirection = newDirection;
    setSortingArrays(newArray);
  }

  return (
    <Tile style={{ overflow: "auto" }}>
      <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Alle Projects
        </Typography>
      <Table className="table justify-content-between">
        <TableHead>
          <TableRow>
            <TableCell scope="col" onClick={handleSortByProject}><div style={{ display: 'flex',alignItems: 'center' }}> <span style={{ marginRight: '8px' }}>Project </span><SortingArrow value={sortingArrays[0].sortDirection} ></SortingArrow> </div></TableCell>
            <TableCell scope="col" onClick={handleSortByLastUpdate}><div style={{ display: 'flex',alignItems: 'center' }}> <span style={{ marginRight: '8px' }}>Last Update </span><SortingArrow value={sortingArrays[1].sortDirection} ></SortingArrow></div></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isSuccess && sortedData.map(project => (
            <TableRow key={project.id} style={{ cursor: "pointer" }} onClick={() => navigate(`/projects/${project.id}`)}>
              <TableCell  >{project.name}</TableCell >
              <TableCell  >{new Date(project.last_update).toLocaleString()}</TableCell >
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Tile >
  );
}

export default ProjectOverview;