import Logo from "../assets/img/logo.webp";
import "../assets/styles/Login.css";
import { loadingImages } from "../components/LoadingImages.model";
import { Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../service/auth";
import { useDiscordOauth } from "../utils/oauth";
import LoadingTile from "../components/tiles/LoadingTile";
import { useRedirectStore } from "../stores/RedirectStore";

const BackgroundImage = styled.div`
  ::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(5px);
  }

  background: url(${loadingImages[Math.floor(Math.random() * (loadingImages.length))]}) no-repeat center center fixed;
  background-size: cover !important;
  width: 100%;
  height: 100%;
  z-index: -999999;
  position: absolute;
`;

const Oauth = () => {
  const { login } = useAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const { data, isLoading, isError } = useDiscordOauth(code as string);
  const navigate = useNavigate(); // Add this line
  const setRedirect = useRedirectStore(state => state.setRedirect);
  const redirectUrl = sessionStorage.getItem('redirectUrl');
  const redirectSearch = sessionStorage.getItem('redirectSearch');
  if (redirectUrl) {
    setRedirect(`${redirectUrl}${redirectSearch}`);
    sessionStorage.removeItem('redirectUrl');
    sessionStorage.removeItem('redirectSearch');
  }

  const checkLogin = async (token: string) => {
    try {
      await login(token);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  if (isError) {
    navigate('/login'); // Add this line
  }
  if (data && !isLoading) {
    checkLogin(data.access_token);
  }

  return (
    <div className="App">
      <BackgroundImage></BackgroundImage>
      <div className="header"></div>
      <Card className="card-style">
        <Card.Body className="body-style">
          <img src={Logo} alt="Logo" className="image" />
          <Card.Title>Oauth Redirect</Card.Title>
          <Card.Text className="text-style">
            Please wait...
          </Card.Text >
          {isLoading ? <LoadingTile></LoadingTile> : <></>}
        </Card.Body>
      </Card>
    </div >
  );
}

export default Oauth;
