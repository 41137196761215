
import Tile from "../Tile";
import { useNavigate, useParams } from "react-router-dom";
import LoadingTile from "./LoadingTile";
import ErrorAlert from "../alerts/ErrorAlert";
import { useEffect, useState } from "react";
import SortingArrow from "../SortingArrow";
import { PullData } from "../../utils/pulls";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { UseQueryResult } from "@tanstack/react-query";


interface SessionStats {
  fight_name: string;
  boss_id: string;
  logcount: number;
  fastest_kill: number | null;
  last_pull: Date;
  is_cm: boolean;
  logs_with_data: number;
}



const PullBossOverview = (props: {
  usePullsFunc: (selected_id: string) => UseQueryResult<PullData[], unknown>,
  isSession: boolean;
}) => {
  const navigate = useNavigate()
  const { id= "-1" } = useParams();
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const { data, isLoading, isError, isSuccess } = props.usePullsFunc(id)
  const [currentSort, setCurrentSort] = useState(3);
  const [SessionStatsArr, setSessionStats] = useState<SessionStats[]>([]);
  const [sortedOverview, setsortedOverview] = useState<SessionStats[]>([]);
  const urlPrefix = props.isSession ? "/sessions" : "/projects";
  
  const [sortingArrays, setSortingArrays] = useState([
    { name: "fight_name", sortDirection: 2 },
    { name: "logcount", sortDirection: 2 },
    { name: "fastest_kill", sortDirection: 2 },
    { name: "last_pull", sortDirection: 2 },
  ]);

  useEffect(() => {
    if (isSuccess) {
      const groupedData: SessionStats[] = data.reduce((acc: SessionStats[], cur: PullData) => {
        const found = acc.find((el) => (el.boss_id === cur.boss_id) && (el.is_cm === cur.is_cm));
        if (found) {
          found.logcount++;
          if (cur.success && (found.fastest_kill === null || found.fastest_kill > cur.duration)) {
            found.fastest_kill = cur.duration;
          }
          if (found.last_pull < cur.end_time) {
            found.last_pull = cur.end_time;
          }
          if (cur.last_phase !== "None") {
            found.logs_with_data++;
          }
        } else {
          acc.push({
            fight_name: cur.fight_name,
            logcount: 1,
            fastest_kill: null,
            last_pull: cur.end_time,
            boss_id: cur.boss_id,
            is_cm: cur.is_cm,
            logs_with_data: cur.last_phase === "None" ? 0 : 1
          });
        }
        return acc;
      }, []);
    
      setSessionStats(groupedData);
    }
  }, [data, isSuccess]);

  
  useEffect(() => {
    if (currentSort === 0) {
      setsortedOverview(SessionStatsArr.sort((a, b) => sortOrder === 'asc' ? a.fight_name.localeCompare(b.fight_name) : b.fight_name.localeCompare(a.fight_name)));
    } else if (currentSort === 1) {
      setsortedOverview(SessionStatsArr.sort((a, b) => sortOrder === 'asc' ? a.logcount - b.logcount : b.logcount - a.logcount));
    } else {
      setsortedOverview(SessionStatsArr.sort((a, b) => sortOrder === 'asc' ? new Date(a.last_pull).getTime() - new Date(b.last_pull).getTime() : new Date(b.last_pull).getTime() - new Date(a.last_pull).getTime()));
    }
  }, [sortOrder, currentSort, SessionStatsArr]);

  if (isLoading) return <LoadingTile />

  if (isError) return <ErrorAlert> No Pulls found.</ErrorAlert>;


  const handleSortByPull = () => {
    const sortedData = SessionStatsArr.sort((a, b) => sortOrder === 'asc' ? a.fight_name.localeCompare(b.fight_name) : b.fight_name.localeCompare(a.fight_name));
    setSessionStats(sortedData);
    resetArrows();
    handleSortChange("fight_name", (sortOrder === 'asc' ? 0 : 1));
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setCurrentSort(0);
  };

  const handleSortByLogCount = () => {
    const sortedData = SessionStatsArr.sort((a, b) => sortOrder === 'asc' ? a.logcount - b.logcount : b.logcount - a.logcount);
    setSessionStats(sortedData);
    resetArrows();
    handleSortChange("logcount", (sortOrder === 'asc' ? 0 : 1));
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setCurrentSort(1);
  };

  // const handleSortByFastest = () => {
  //   const sortedData = SessionStatsArr.sort((a, b) => sortOrder === 'asc' ? a.fastest_kill - b.fastest_kill : b.fastest_kill - a.fastest_kill);
  //   setSessionStats(sortedData);
  //   resetArrows();
  //   handleSortChange("fastest_kill", (sortOrder === 'asc' ? 0 : 1));
  //   setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  //   setCurrentSort(2);
  // }

  const handleSortByLastPull = () => {
    const sortedData = SessionStatsArr.sort((a, b) => sortOrder === 'asc' ? new Date(a.last_pull).getTime() - new Date(b.last_pull).getTime() : new Date(b.last_pull).getTime() - new Date(a.last_pull).getTime());
    setSessionStats(sortedData);
    resetArrows();
    handleSortChange("last_pull", (sortOrder === 'asc' ? 0 : 1));
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setCurrentSort(2);
  };

  const resetArrows = () => {
    handleSortChange("fight_name", 2);
    handleSortChange("logcount", 2);
    handleSortChange("fastest_kill", 2); 
    handleSortChange("last_pull", 2);   
  }


  const handleSortChange = (arrayName: string, newDirection: any) => {
    const newArray = [...sortingArrays];
    const arrayIndex = newArray.findIndex(array => array.name === arrayName);
    newArray[arrayIndex].sortDirection = newDirection;
    setSortingArrays(newArray);
  }

  return (
    <Tile style={{ overflow: "auto" }}>
      <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
      >
        {props.isSession ? "Session" : "Project"} Encounters
        </Typography>
      <Table className="table justify-content-between">
        <TableHead>
          <TableRow>
            <TableCell scope="col" onClick={handleSortByPull}><div style={{ display: 'flex',alignItems: 'center' }}> <span style={{ marginRight: '8px' }}>Fight Name</span> <SortingArrow value={sortingArrays[0].sortDirection} ></SortingArrow></div></TableCell>
            <TableCell scope="col" onClick={handleSortByLogCount}><div style={{ display: 'flex',alignItems: 'center' }}> <span style={{ marginRight: '8px' }}>Pulls </span><SortingArrow value={sortingArrays[1].sortDirection} ></SortingArrow></div></TableCell>
            <TableCell scope="col" ><div style={{ display: 'flex',alignItems: 'center' }}> <span style={{ marginRight: '8px' }}>Fastest Kill</span> </div></TableCell>
            <TableCell scope="col" onClick={handleSortByLastPull}><div style={{ display: 'flex',alignItems: 'center' }}> <span style={{ marginRight: '8px' }}>Last Update </span><SortingArrow value={sortingArrays[3].sortDirection} ></SortingArrow></div></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isSuccess && sortedOverview.map(sessionBoss => (
            <TableRow key={sessionBoss.boss_id} style={{ cursor: "pointer" }} onClick={() => navigate(`${urlPrefix}/boss/${sessionBoss.boss_id}&${sessionBoss.is_cm}&${id}`,)}>
              <TableCell >{sessionBoss.fight_name}</TableCell>
              <TableCell >{sessionBoss.logs_with_data}/{sessionBoss.logcount}</TableCell>
              <TableCell >{sessionBoss.fastest_kill}</TableCell>
              <TableCell >{new Date(sessionBoss.last_pull).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Tile >
  );
}

export default PullBossOverview;