import Plot from 'react-plotly.js';
import { Pull } from '../../utils/pulls';
import ErrorAlert from '../alerts/ErrorAlert';
import LoadingTile from '../tiles/LoadingTile';
import { getPlotlyFontColor } from '../../theme/theme';
import { useTheme } from '@mui/material';

interface PullPhaseTimesProps{
    filteredPulls: Pull[] | undefined;
  phaseName: string;
  record: Pull | undefined;
  plotByDate: boolean;
}
  
  interface FilteredPhaseValue {
    date: Date;
      value: number;
        link: string;
  }

const PhaseTimesTrendPlot: React.FC<PullPhaseTimesProps> = ({ filteredPulls, phaseName, record, plotByDate }) => {
    
  const theme = useTheme();
  const fontColor = getPlotlyFontColor(theme.palette.mode);
    if (!filteredPulls) {
        return <LoadingTile />
    }
  
    // if (phaseName === "") {
    //     return <div>Please select a phase</div>
    // }
    
    const extractPhasesForName = (dataArray: Pull[], phaseName: string): FilteredPhaseValue[] => {
      return dataArray.filter(entry => entry.phases.hasOwnProperty(phaseName)).map(entry => ({
            date: entry.end_time,
            value: entry.phases[phaseName]['duration'],
            link : entry.id,
        }));
      };
    
    
    if (filteredPulls.length === 0) {
        return <ErrorAlert> No pulls found.</ErrorAlert>;
  }      
  const phasesForName = extractPhasesForName(filteredPulls, phaseName);
  
  const sortedTimes = phasesForName.map(phase => phase.value).sort((a, b) => a - b);
  let median = 0;
  if (sortedTimes.length > 0) {
    const mid = Math.floor(sortedTimes.length / 2);
    median = sortedTimes.length % 2 === 0 ? (sortedTimes[mid - 1] + sortedTimes[mid]) / 2 : sortedTimes[mid];
  }

    const handleClick = (event: any) => {
        const clickedPoint = event.points[0].pointIndex;
        const clickedData = phasesForName[clickedPoint];
        if (clickedData) {
            const href = clickedData.link;
            window.open(href, '_blank', 'noreferrer');
        }
      };
    
  let plotArrays = [];
    const data : Plotly.Data= 
        {
        x: plotByDate ? phasesForName.map(phase => new Date(phase.date)) : phasesForName.map(phase => phase.link),
        y: phasesForName.map(phase => phase.value),
        hovertext: phasesForName.map(phase => new Date(phase.date).toLocaleString()),
        hoverinfo: 'y+text',
        type: 'scatter',
        mode: 'lines+markers',
        marker: { color: '#1565c0' },
        name: 'Phase Duration',
        };
  const medianData: Plotly.Data =
  {
    x: plotByDate ? phasesForName.map(phase => new Date(phase.date)) : phasesForName.map(phase => phase.link),
    y: Array(phasesForName.length).fill(median),
    type: 'scatter',
    mode: 'lines',
    marker: { color: 'red' },
    name: 'Median',
  };

  plotArrays.push(data);
  plotArrays.push(medianData);

  if (record && record.phases.hasOwnProperty(phaseName)) {
    const recordPhaseTime = record.phases[phaseName]['duration'];
    const recordPhaseTimeData: Plotly.Data =
    {
      x: plotByDate ? phasesForName.map(phase => new Date(phase.date)) : phasesForName.map(phase => phase.link),
      y: Array(phasesForName.length).fill(recordPhaseTime),
      type: 'scatter',
      mode: 'lines',
      marker: { color: 'orange' },
      name: 'Record',
    };
    plotArrays.push(recordPhaseTimeData);
  }


    const layout:any = {
        title: "Phase Duration Trend",
      xaxis: {
        title: 'Timestamp',
        timezone: plotByDate ? null : 'cet',
        tickvals: plotByDate ? null : phasesForName.map((phase, index) => index % 10 ===0 || index === phasesForName.length-1 ? phase.link: null),
        ticktext: plotByDate ? null : phasesForName.map((phase, index) => index % 10 === 0 || index === phasesForName.length -1 ? new Date(phase.date).toLocaleString() : null),
      },
        yaxis: { title: 'Phase Duration (seconds)' },
        margin: { t: 30, b: 100, l: 50, r: 25 },
        plot_bgcolor: 'rgba(0,0,0,0)',
        paper_bgcolor: 'rgba(0,0,0,0)',
        font: {
          color: fontColor
        }
        
  };

    return <Plot data={plotArrays} layout={layout} onClick={(event) => handleClick(event)} style={{ width: '100%', height: '100%' }} useResizeHandler={true}/>;
};

export default PhaseTimesTrendPlot;
