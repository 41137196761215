import React from 'react';
import { Pull } from '../../utils/pulls';
import ErrorAlert from '../alerts/ErrorAlert';
import WarnAlert from '../alerts/WarnAlert';
import { Grid } from '@mui/material';
import Tile from '../Tile';
import PlayerPullsStatsMaxMedian from './PlayerPullsStatsMaxMedian';
import PlayerDpsDevelopment from './PlayerDpsDevelopment';
import SinglePlayerDpsAtSec from './SinglePlayerDpsAtSec';
import ComparePlayerDpsAtSec from './ComparePlayerDpsAtSec';
import { insertIntoSortedArray, insertIntoSortedDict } from '../../utils/sorted_insert';

interface PullPhaseTimesProps{
  filteredPulls: Pull[] | undefined;
  phaseName: string;
  record: Pull | undefined;
}

interface FilteredPhaseValue {
    date: Date;
    value: number;
    link: string;
}
  
type Player = {
    name: string;
    profession: string;
    maxDPS: number;
    medianDPS: number;
    endTime: Date;
    link: string;
}

type ProfMedian = {
    [profession: string]: number;
}

type StatsPerPhase = {
    players: Player[];
    profession: ProfMedian;
}

const PlayerPullsStats: React.FC<PullPhaseTimesProps> = ({ filteredPulls, phaseName, record }) => {
    

  if (!filteredPulls) {
    return <ErrorAlert> No pulls found.</ErrorAlert>;
  }
  if (!phaseName) {
    return <ErrorAlert> No phase selected.</ErrorAlert>;
  }
  
  const extractPhasesForName = (filteredPulls: Pull[], phaseName: string): StatsPerPhase => {
    
    const PlayerDPS: any = {};
    
    const ProfessionDPS: Record<string, number[]> = {};
    for (const pull of filteredPulls) {
      for (const key in pull.players) {
        // Extract the last entry for the current key
        if (pull.players[key]["dps"].hasOwnProperty(phaseName) === false) {
          continue;
        }        
        const profession = pull.players[key].role;
        let dps = pull.players[key].dps[phaseName];
        if (isNaN(dps)) {
          dps = 0;
        }
        else {
          dps = Math.round(dps);
        }
        if (PlayerDPS.hasOwnProperty(key)) {
          if (PlayerDPS[key].hasOwnProperty(profession)) {
            PlayerDPS[key][profession] = insertIntoSortedDict(PlayerDPS[key][profession], { value:dps, date: pull.end_time, link: pull.id});            
          }
          else {
            PlayerDPS[key][profession] = [{ value:dps, date: pull.end_time, link: pull.id}];
          }
        }
        else {
          PlayerDPS[key] = { [profession]: [{ value:dps, date: pull.end_time, link: pull.id}] };
        }

        if (ProfessionDPS.hasOwnProperty(profession)) {
          ProfessionDPS[profession] = insertIntoSortedArray(ProfessionDPS[profession], dps);
        }
        else {
          ProfessionDPS[profession] = [dps];
        }
        
      }
    }

    const PlayerDPSArray: Player[] = [];
    for (const key in PlayerDPS) {
      for (const profession in PlayerDPS[key]) {
        const dps: FilteredPhaseValue[] = PlayerDPS[key][profession]
        const bestpull = dps[dps.length - 1];
        const medianDPS = dps.length % 2 === 0 ? (dps[dps.length / 2 - 1].value + dps[dps.length / 2].value) / 2 : dps[Math.floor(dps.length / 2)].value;
        const player = { name: key, profession: profession, maxDPS: bestpull.value, medianDPS: medianDPS, endTime: bestpull.date, link: bestpull.link };
        PlayerDPSArray.push(player);
      }
    }
    const ProfessionMedian: ProfMedian = {};
    for (const profession in ProfessionDPS) {
      ProfessionMedian[profession] = ProfessionDPS[profession][Math.floor(ProfessionDPS[profession].length / 2)];
    }

    return {players: PlayerDPSArray, profession: ProfessionMedian};
  };
    

  const verifyFilter = filteredPulls.filter(entry => entry.phases.hasOwnProperty(phaseName));
  const statsPerPhase = extractPhasesForName(verifyFilter, phaseName);
  const players = statsPerPhase.players;
  const professionMedian = statsPerPhase.profession;
  players.sort((a, b) => {return b.medianDPS - a.medianDPS || a.profession.localeCompare(b.profession)});
  
  if (players.length === 0) {
      return <WarnAlert> No data for this phase.</WarnAlert>;
  }

  return (
    <Grid container item xs={12} spacing={1} style={{ height: '40%' }}>
      <Grid item xs={12} md={6} height={500}>
        <Tile>
          <PlayerPullsStatsMaxMedian professionMedian={professionMedian} players={players} isMax={false}/>
        </Tile>
      </Grid>
      <Grid item xs={12} md={6} height={500}>
        <Tile>
          <PlayerPullsStatsMaxMedian professionMedian={professionMedian} players={players} isMax={true}/>
        </Tile>
      </Grid>
      <Grid item xs={12} md={6} height={500}>
      <Tile>
          <SinglePlayerDpsAtSec filteredPulls={filteredPulls} phaseName={phaseName} record={record} />
          </Tile>
      </Grid> 
      <Grid item xs={12} md={6} height={500}>
        <Tile>
            <ComparePlayerDpsAtSec filteredPulls={filteredPulls} phaseName={phaseName} />
        </Tile>
      </Grid> 
      <Grid item xs={12} md={12} height={500}>
        <Tile>
          <PlayerDpsDevelopment filteredPulls={filteredPulls} phaseName={phaseName} record={record}/>
        </Tile>
      </Grid>
    </Grid>
  );
};

export default PlayerPullsStats;
