import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type HTML = {
    html: string;
};


export const useReport = (report_id: string) => {
    return useQuery<HTML>(
      ["pulls", report_id],
      () => {
        return axios
          .get(`/log/report?report_id=${report_id}`)
            .then((e) => new Promise<HTML>((resolve, reject) => {
                resolve({html: e.data});
            }));
      },
      {
          refetchOnWindowFocus: false,
          retry: false,
        staleTime: 30000 /* 30s */ /* cacheTime: 300000 */ /* 5min */,
      }
    );
  }