import React, { useEffect } from 'react';
import Plot from 'react-plotly.js';
import { Pull } from '../../utils/pulls';
import WarnAlert from '../alerts/WarnAlert';
import { Grid, InputLabel, MenuItem, Select, Stack, useTheme } from '@mui/material';
import { getPlotlyFontColor } from '../../theme/theme';

interface SinglePlayerDpsAtSecProps{
  filteredPulls: Pull[] | undefined;
  phaseName: string;
}

type PlayerStats = {
  name: string;
  topPullDps: number[];
  topPullSec: number[];
  topLink: string;
  worstPullDps: number[];
  worstPullSec: number[];
  worstLink: string;
  medianDps: number[];
}

type PullPlayer = {
      dpsAtSec: number[];
      date: Date;
      link: string;
}

const gatherProfessionMedian = (dataArray: Pull[], phaseName: string, playerProfession:string): number[] => {
    
  const professionMedian: number[][] = [];
  let longest = 0;
  for (const pull of dataArray) {
    if (!pull.phases.hasOwnProperty(phaseName)) {
      continue;
    }

    for (const player of Object.values(pull.players)) {
      if (player.role !== playerProfession) {
        continue;
      }
      professionMedian.push(player.dmg_at_second[phaseName]);
      if (player.dmg_at_second[phaseName].length > longest) {
        longest = player.dmg_at_second[phaseName].length;
      }
    }
  }
  const seconds = Array.from({ length: longest }, (_, index) => index);
  const ProfessionMedian = seconds.map((second) => {
    const valuesAtSecond = professionMedian.map((arr) => (second < arr.length ? second === 0 ? 0 : arr[second]/ second : null));
    const validValues = valuesAtSecond.filter((value): value is number => value !== null);
    
    if (validValues.length === 0) {
      return 0;
    }

    const sortedValues = validValues.sort((a, b) => a - b);
    const mid = Math.floor(sortedValues.length / 2);
    return sortedValues.length % 2 !== 0 ? sortedValues[mid] : (sortedValues[mid - 1] + sortedValues[mid]) / 2;
  });

  return ProfessionMedian;
};

const gatherDpsData = (dataArray: Pull[], phaseName: string, currentPlayerNames: string[], playerProfession:string): PlayerStats[] => {
  const output: PlayerStats[] = [];

  for (const currentPlayerName of currentPlayerNames) {
    const professionPlayer: PullPlayer[] = [];
    let longest = 0;
    let highestIndex = -1;
    let highestDps = 0;
    let lowestDps = 0;
    let lowestIndex = -1;
    let highestLink = "";
    let lowestLink = "";
    let index = -1;
    for (const pull of dataArray) {
      index++;
      if (!pull.phases.hasOwnProperty(phaseName)) {
        continue;
      }

      for (const [PullPlayerName, player] of Object.entries(pull.players)) {
        if (player.role !== playerProfession) {
          continue;
        }
        if (player.dmg_at_second[phaseName].length > longest) {
          longest = player.dmg_at_second[phaseName].length;
        }

        if (currentPlayerName === PullPlayerName && player.dmg_at_second.hasOwnProperty(phaseName)) {
          professionPlayer.push({
            dpsAtSec: player.dmg_at_second[phaseName],
            date: pull.end_time,
            link: pull.id,
          });
          if (player.dps[phaseName] > highestDps) {
            highestDps = player.dps[phaseName];
            highestIndex = index;
            highestLink = pull.id;
          }
          if (player.dps[phaseName] < lowestDps || lowestDps === 0) {
            lowestDps = player.dps[phaseName];
            lowestIndex = index;
            lowestLink = pull.id;
          }
        }
      }
    }

    const seconds = Array.from({ length: longest }, (_, index) => index);
    const playerMedian = seconds.map((second) => {
      const valuesAtSecond = professionPlayer.map((arr) => (second < arr.dpsAtSec.length ? second === 0 ? 0 : arr.dpsAtSec[second] / second : null));
      const validValues = valuesAtSecond.filter((value): value is number => value !== null);
      
      if (validValues.length === 0) {
        return 0;
      }

      const sortedValues = validValues.sort((a, b) => a - b);
      const mid = Math.floor(sortedValues.length / 2);
      return sortedValues.length % 2 !== 0 ? sortedValues[mid] : (sortedValues[mid - 1] + sortedValues[mid]) / 2;
    });
    
    if (highestIndex === -1 || lowestIndex === -1) {
      continue;
    }

    const topDpsArr = dataArray[highestIndex].players[currentPlayerName].dmg_at_second[phaseName]
    const bestPullSec = Array.from({ length: topDpsArr.length }, (_, index) => index + 1 === topDpsArr.length ? dataArray[highestIndex].phases[phaseName]["duration"] : index)
    const topDps = topDpsArr.map((dmg, index) => bestPullSec[index] === 0 ? 0 : Math.round(dmg / bestPullSec[index]));
    
    const worstDpsArr = dataArray[lowestIndex].players[currentPlayerName].dmg_at_second[phaseName]
    const worstPullSec = Array.from({ length: worstDpsArr.length }, (_, index) => index + 1 === worstDpsArr.length ? dataArray[lowestIndex].phases[phaseName]["duration"] : index)
    const worstDps = worstDpsArr.map((dmg, index) => worstPullSec[index] === 0 ? 0 : Math.round(dmg / worstPullSec[index]));


    output.push({
      name: currentPlayerName,
      topPullDps: topDps,
      topPullSec: bestPullSec,
      topLink: highestLink,
      worstPullDps: worstDps,
      worstPullSec: worstPullSec,
      worstLink: lowestLink,
      medianDps: playerMedian,
    });
  }

  return output;
};

const ComparePlayerDpsAtSec: React.FC<SinglePlayerDpsAtSecProps> = ({ filteredPulls, phaseName }) => {
  const theme = useTheme();
  const fontColor = getPlotlyFontColor(theme.palette.mode);
  const [playerNameOne, setPlayerName] = React.useState<string>('');
  const [playerNameTwo, setPlayerNameTwo] = React.useState<string>('');
  const playerProfessions = Array.from(new Set(filteredPulls?.map(pull => Object.values(pull.players).map(player => player.role)).flat()));
  const [playerProfession, setPlayerProfession] = React.useState<string>(playerProfessions[0] ? playerProfessions[0] : '');
  const filteredPlayerNames = Array.from(new Set(filteredPulls?.map(pull => Object.entries(pull.players).map(([name, player]) => playerProfession === player.role ? name : null )).flat()));

  const playerNames = filteredPlayerNames.filter((name): name is string => name !== null);
  const playerNamesTwo = playerNames.filter((name) => name !== playerNameOne);

  useEffect(() => {
    if (!playerNames.includes(playerNameOne)) {
      setPlayerName("");
    }
    if (!playerNames.includes(playerNameTwo)) {
      setPlayerNameTwo("");
    }
    if (!playerNames.includes(playerNameOne)) {
      setPlayerName(playerNames[0] ? playerNames[0] : '');
    }
  }, [playerNames, playerNameOne, playerNameTwo])

  
  if (!filteredPulls) {
    return <WarnAlert> No pulls to render.</WarnAlert>
  }

  if (!phaseName) {
    return <WarnAlert> No phase selected.</WarnAlert>
  }  

  let dpsData: PlayerStats[] = [];
  if (playerProfession !== '' && playerNameOne !== '' && playerNames.includes(playerNameOne)) {
    dpsData = gatherDpsData(filteredPulls, phaseName, [playerNameOne, playerNameTwo], playerProfession);
  }

  const profMedian = gatherProfessionMedian(filteredPulls, phaseName, playerProfession);

  const handleClick = (event: any) => {
    const clickedPoint = event.points[0];
    if (clickedPoint.data.text !== undefined) {
      window.open(clickedPoint.data.text, '_blank');
    }
    };
    
  let plotArrays: Plotly.Data[] = [];

  for (const player of dpsData) {
    plotArrays.push(
      {
        y: player.medianDps,
        x: Array.from({ length: player.medianDps.length }, (_, index) => index),
        type: 'scatter',
        mode: 'lines',
        name: player.name + " Median",
        hoverinfo: 'x+y+name',
        marker: { color: '#1565c0' },
      });
    plotArrays.push(
      {
        y: player.topPullDps,
        x: player.topPullSec,
        type: 'scatter',
        mode: 'lines',
        name: player.name + " Best",
        hoverinfo: 'x+y+name',
        text: player.topLink,
        marker: { color: 'green' },
      });
    plotArrays.push(
      {
        y: player.worstPullDps,
        x: player.worstPullSec,
        type: 'scatter',
        mode: 'lines',
        name: player.name + " Worst",
        text: player.worstLink,
        hoverinfo: 'x+y+name',
        marker: { color: 'red' },
      });
  }
  plotArrays.push(
    {
      y: profMedian,
      x: Array.from({ length: profMedian.length }, (_, index) => index ),
      type: 'scatter',
      mode: 'lines',
      name: playerProfession + " Median",
      hoverinfo: 'x+y+name',
      marker: { color: 'orange' },
    });

  const layout:any = {
    title: "DPS per Second",
    xaxis: { title: 'Second'},
      yaxis: { title: 'DPS' },
      margin: { t: 30, b: 100, l: 50, r: 25 },
      plot_bgcolor: 'rgba(0,0,0,0)',
    paper_bgcolor: 'rgba(0,0,0,0)',
    legend: {
      orientation: 'h',
      x: 1.0,
      y: 0.1,
      xanchor: 'auto',
      yanchor: 'auto',
      traceorder: 'normal',
    },
    font: {
      color: fontColor
    }
  };
  return (
    <Grid>
      <Stack spacing={2} direction="row" alignItems="center">
        <Grid item >
        <InputLabel id="prof-select-label">Profession</InputLabel>
          <Select 
            labelId="prof-select-label"
              defaultValue={playerProfession}
              value={playerProfession}
            onChange={(e) => setPlayerProfession(e.target.value as string)}
            >
              {playerProfessions?.map((prof) => (
              <MenuItem value={prof}>{prof}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item >
            <InputLabel id="player-select-label">Player</InputLabel>
            <Select 
              labelId="player-select-label"
                defaultValue={playerNameOne}
                value={playerNameOne}
              onChange={(e) => setPlayerName(e.target.value as string)}
              >
                {playerNames?.map((name) => (
                <MenuItem value={name}>{name}</MenuItem>
              ))}
            </Select>
        </Grid>
        <Grid item >
            <InputLabel id="player-select-label">2. Player</InputLabel>
            <Select 
              labelId="player-select-label"
                defaultValue={playerNameTwo}
                value={playerNameTwo}
              onChange={(e) => setPlayerNameTwo(e.target.value as string)}
              >
                {playerNamesTwo?.map((name) => (
                <MenuItem value={name}>{name}</MenuItem>
              ))}
            </Select>
        </Grid>
        </Stack>
        < Plot data = { plotArrays } onClick={(event) => handleClick(event)} layout = { layout } style = {{ width: '100%', height: '100%' }} useResizeHandler = { true} />
      </Grid>
      )
};

export default ComparePlayerDpsAtSec;
