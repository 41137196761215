import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type Project = {
  /**
   * Session ID
   */
  id: string;

  /**
   * Guild ID
   */
  guild_id: string;

  /**
   * Session name
   */
  name: string;

  /**
   * Last update time
   */
  last_update: Date;

  /**
   * Last log type
   */
  last_logtype: number;

  /**
   * Timezone
   */
  timezone: string;

  /**
   * Participants' account names
   */
  participants_accnames: string[];

  /**
   * Summaries
   */
  summaries: Record<string, any>;

  /**
   * Player stats
   */
  player_stats: Record<string, any>;

  /**
   * Total stats
   */
  total_stats: Record<string, any>;
};

export const useProjects = () => {
  return useQuery<Project[]>(
    ["projects"],
    () => {
      return axios.get(`/projects/all`).then((e) => e.data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 300000 /* 5min */,
    }
  );
};

export const useProject = (project_id: string) => {
  return useQuery<Project>(
    ["projects", project_id],
    () => {
      return axios
        .get(`/projects?project_id=${encodeURIComponent(project_id)}`)
        .then((e) => e.data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 30000 /* 30s */ /* cacheTime: 300000 */ /* 5min */,
    }
  );
};


export const useBossProjects = (boss_id: string) => {
  return useQuery<Project[]>(
      ["boss_id", boss_id],
      () => {
          return axios.get(`/boss/projects?boss_id=${boss_id}`).then((e) => e.data);
      }
  );
}