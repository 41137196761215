import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type Session = {
  /**
   * Session ID
   */
  id: string;

  /**
   * Guild ID
   */
  guild_id: string;

  /**
   * Session name
   */
  name: string;

  /**
   * Last update time
   */
  last_update: Date;

  /**
   * Last log type
   */
  last_logtype: number;

  /**
   * Timezone
   */
  timezone: string;

  /**
   * Participants' account names
   */
  participants_accnames: string[];

  /**
   * Summaries
   */
  summaries: Record<string, any>;

  /**
   * Player stats
   */
  player_stats: Record<string, any>;

  /**
   * Total stats
   */
  total_stats: Record<string, any>;

  /**
   * Name of the owner
   */
  owner: string;

  guild_name: string;
};

export const useSessions = () => {
  return useQuery<Session[]>(
    ["sessions/all"],
    () => {
      return axios.get(`/sessions/all?`).then((e) => e.data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 300000 /* 5min */,
    }
  );
};

export const useSession = (session_id: string) => {
  return useQuery<Session>(
    ["sessions/", session_id],
    () => {
      return axios
        .get(`/sessions?session_id=${encodeURIComponent(session_id)}`)
        .then((e) => e.data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 30000 /* 30s */ /* cacheTime: 300000 */ /* 5min */,
    }
  );
};


export const useBossSessions = (boss_id: string, isCm: boolean) => {
  return useQuery<Session[]>(
      ["boss/sessions", boss_id, isCm],
      () => {
          return axios.get(`/boss/sessions?boss_id=${boss_id}&is_cm=${isCm}`).then((e) => e.data);
      }
  );
}

export const useAcountSessions = (discord_id: string) => {
  return useQuery<Session[]>(
      ["/session/participation", discord_id],
      () => {
          return axios.get(`/session/participation?discord_id=${discord_id}`).then((e) => e.data);
      },
      {
        retry: false,
      }
  );
}