import React from 'react';
import Plot from 'react-plotly.js';
import { Pull } from '../../utils/pulls';
import WarnAlert from '../alerts/WarnAlert';
import { useTheme } from '@mui/material';
import { getPlotlyFontColor } from '../../theme/theme';
interface PlayerDpsDevelopmentProps{
  filteredPulls: Pull[] | undefined;
  phaseName: string;
  record: Pull | undefined;
}

function generateColorPalette(numEntries: number): string[] {
  const palette: string[] = [];
  const step = 360 / numEntries;

  for (let i = 0; i < numEntries; i++) {
    const hue = i * step;
    const color = `hsl(${hue}, 70%, 50%)`;
    palette.push(color);
  }

  return palette;
}

type ProfessionPlayer = {
  profession: string;
  playerName: string;
  dps: number[];
  date: Date[];
  link: string[];
  index: number;
}

type ProfessionPlayerDict = {
  [profession: string]: {
    [playerName: string]: {
      dps: number[];
      date: Date[];
      link: string[];
      index: number;
    }
  }
}

const PlayerDpsDevelopment: React.FC<PlayerDpsDevelopmentProps> = ({ filteredPulls, phaseName, record }) => {
  const theme = useTheme();
  const fontColor = getPlotlyFontColor(theme.palette.mode);
  if (!filteredPulls) {
    return <WarnAlert> No pulls to render.</WarnAlert>
  }

  const gatherDpsData = (dataArray: Pull[], phaseName: string): ProfessionPlayer[] => {
    const professionPlayer: ProfessionPlayerDict = {};
    let counter = 0;
    for (const pull of dataArray) {
      if (!pull.phases.hasOwnProperty(phaseName)) {
        continue;
      }

      for (const [playerName, player] of Object.entries(pull.players)) {
        if (!professionPlayer.hasOwnProperty(player.role)) {
          professionPlayer[player.role] = {};
        }
        if (!professionPlayer[player.role].hasOwnProperty(playerName)) {
          professionPlayer[player.role][playerName] = {
            dps: [],
            date: [],
            link: [],
            index : counter
          };
          counter++;
        }
        professionPlayer[player.role][playerName].dps.push(player.dps[phaseName]);
        professionPlayer[player.role][playerName].date.push(pull.end_time);
        professionPlayer[player.role][playerName].link.push(pull.id);
      }
    }

    const output: ProfessionPlayer[] = [];

    for (const [profession, playerdict] of Object.entries(professionPlayer)) {
      for (const [playerName, player] of Object.entries(playerdict)) {
        output.push({
          profession: profession,
          playerName: playerName,
          dps: player.dps,
          date: player.date,
          link: player.link,
          index: player.index
        });
      }
    }

    return output;
  };
  
  const dpsData = gatherDpsData(filteredPulls, phaseName);

  dpsData.sort((a, b) => {return b.dps[b.dps.length - 1] - a.dps[a.dps.length - 1] || a.profession.localeCompare(b.profession)});

  const handleClick = (event: any) => {
    const clickedPoint = event.points[0];
    if (clickedPoint > dpsData.length) {
      return
    }
        window.open(clickedPoint.x, '_blank');
    };
    
  let plotArrays: any = [];
  const colorPalette = generateColorPalette(dpsData.length)
  for (const player of dpsData) {
      const data: Plotly.Data =
      {
        x: player.link,
        y: player.dps,
        type: 'scatter',
        mode: 'lines',
        marker: { color: colorPalette[player.index%colorPalette.length] },
        name: player.playerName + " (" + player.profession + ")",
        hoverinfo: 'y+text',
        text: player.playerName + " (" + player.profession + ")",
      };
      plotArrays.push(data);
  }

    const layout:any = {
      title: "DPS per Pull",
      xaxis: { title: 'Pull', showticklabels: false, showgrid: false },
        yaxis: { title: 'DPS' },
        margin: { t: 30, b: 100, l: 50, r: 25 },
        plot_bgcolor: 'rgba(0,0,0,0)',
      paper_bgcolor: 'rgba(0,0,0,0)',
      legend: {
        orientation: 'h',
        x: 0.5,
        y: -0.3,
        xanchor: 'center',
        yanchor: 'top',
        traceorder: 'normal',
      },
      font: {
      color: fontColor
    }
        
    };

  return < Plot data = { plotArrays } layout = { layout } onClick={(event) => handleClick(event)} style = {{ width: '100%', height: '100%' }} useResizeHandler = { true} />;
};

export default PlayerDpsDevelopment;
