import React from 'react';
import Plot from 'react-plotly.js';
import { Pull } from '../../utils/pulls';
import WarnAlert from '../alerts/WarnAlert';
import { Layout, Shape } from 'plotly.js';
import { useTheme } from '@mui/material';
import { getPlotlyFontColor } from '../../theme/theme';

interface PullPhaseDurationsProps{
    filteredPulls: Pull[] | undefined;
  phaseName: string;
  calcSum: boolean;
  barChart: boolean;
  record: Pull | undefined;
}
  
interface PlotData {
    x: string[];
    y: number[];
  sum: number;
  link: string;
}

function generateColorPalette(numEntries: number): string[] {
  const palette: string[] = [];
  const step = 360 / numEntries;

  for (let i = 0; i < numEntries; i++) {
    const hue = i * step;
    const color = `hsl(${hue}, 70%, 50%)`;
    palette.push(color);
  }

  return palette;
}

const PullPhaseDurationsPlot: React.FC<PullPhaseDurationsProps> = ({ filteredPulls, phaseName,  calcSum, barChart, record}) => {
    const theme = useTheme();
    const fontColor = getPlotlyFontColor(theme.palette.mode);
    if (!filteredPulls) {
        return <WarnAlert> No pulls to render.</WarnAlert>
    }  
    
    let plotDataDict: PlotData[] = [];
    filteredPulls.forEach((pull) => {
      let pullX = [];
      let pullY = [];
      let sum = 0;
      for (const [key, value] of Object.entries(pull.phases)) {
        if (["fullfight", "kills", "kill"].includes(key.toLowerCase().replace(" ", ""))) {
          continue;
        }
        sum += value.duration;
        pullX.push(key);
        if (calcSum) {
          pullY.push(sum);
        } else {
          pullY.push(value.duration);
        }
        if (key === phaseName && !["fullfight", "kills", "kill"].includes(key.toLowerCase().replace(" ", ""))) {
          break;
        }
      }
      sum = Math.round((sum + Number.EPSILON) * 1000) / 1000
      plotDataDict.push({ x: pullX, y: pullY, sum: sum, link: pull.id });
    });
  
    const handleClick = (event: any) => {
        let clickedCurve = event.points[0].curveNumber;
        if (record && record.phases.hasOwnProperty(phaseName)){
          if(clickedCurve === 0){
            const href = record.id;
            window.open(href, '_blank', 'noreferrer');
          return
          }
          clickedCurve = clickedCurve - 1;
        }
        const clickedData = plotDataDict[clickedCurve];
        if (clickedData) {
            const href = clickedData.link;
            window.open(href, '_blank', 'noreferrer');
        }
      };
    
  let plotData: Plotly.Data[] = [];
  const type = barChart ? "bar" : 'scatter';
  const numberOfPlots = barChart ? 5 : 3;
  let shapes: Partial<Shape>[] = [];
  if (record) {
    let pullX = [];
    let pullY = [];
    let sum = 0;
    let x_value = -0.4;
    for (const [key, value] of Object.entries(record.phases)) {
      if (["fullfight", "kills", "kill"].includes(key.toLowerCase().replace(" ", ""))) {
        continue;
      }
      sum += value.duration;
      pullX.push(key);
      if (calcSum) {
        if (barChart) {
          shapes.push({
            x0: x_value, x1: x_value + 0.8, y0: sum, y1: sum, type: 'line', line: {
              color: 'orange',
              width: 2,
            }
          });
        }
        pullY.push(sum);
      } else {
        pullY.push(value.duration);
        if (barChart) {
          shapes.push({
            x0: x_value, x1: x_value + 0.8, y0: value.duration, y1: value.duration, type: 'line', line: {
              color: 'orange',
              width: 2,
            }
          });
        }
      }
      x_value += 1;
      if (key === phaseName && !["fullfight", "kills", "kill"].includes(key.toLowerCase().replace(" ", ""))) {
        break;
      }
    }
    plotData.push({
      x: pullX,
      y: pullY,
      type: type,
      mode: 'lines+markers',
      marker: { color: "orange" },
      name: "Record"
    })
  }

  let counter = 0;
  const colorPalette = generateColorPalette(plotDataDict.length);
  plotDataDict = plotDataDict.sort((prev, curr) => prev.sum - curr.sum);
  plotDataDict.forEach((pull) => {
    counter++;
    const mode = pull.x.length > 1 ? 'lines+markers' : 'markers';


    if (counter <= numberOfPlots) {
      plotData.push({
        x: pull.x,
        y: pull.y,
        type: type,
        mode: mode,
        marker: { color: colorPalette[counter%colorPalette.length] },
        name: pull.sum + "s"
      })}
    else {
          plotData.push({
            x: pull.x,
            y: pull.y,
            type: type,
            mode: mode,
            marker: { color: colorPalette[counter%colorPalette.length] },
            name: pull.sum + "s",
            visible: 'legendonly',
          })
        }
      
  });
  
  const chatName = calcSum ? "Sum of Phases" : "Phase Duration";
    const layout: Partial<Layout> = {
        title: chatName,
        xaxis: { title: 'Phase Name' },
      yaxis: { title: 'Phase Duration (seconds)' },
      plot_bgcolor: "rgba(0, 0, 0,0)",
      paper_bgcolor: "rgba(0, 0, 0,0)",
      showlegend: true,
      legend: {
        orientation: 'h',
        x: 0.5,
        y: -0.3,
        xanchor: 'center',
        yanchor: 'top',
        traceorder: 'normal',
    },
      margin: { t: 30, b: 100, l: 50, r: 25 },
      shapes: shapes,
      font: {
        color: fontColor
      }

      
    };
  return <Plot data={plotData} layout={layout} style={{ width: '100%', height: '100%' }} useResizeHandler={true} onClick={(event) => handleClick(event)}/>;
};

export default PullPhaseDurationsPlot;
