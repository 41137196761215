import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { refetchInterval } from "../config/config";

export type PullData = {
  id: string;
  fight_name: string;
  boss_id: string;
  comp: string;
  duration: number;
  wingman_record: string;
  success: boolean;
  boss_hp: number;
  end_time: Date;
  start_time: Date;
  is_cm: boolean;
  gwacc_names: string[];
  last_phase: string;
};

export type Pull = {
  id: string;
  fight_name: string;
  boss_id: string;
  comp: string;
  duration: number;
  wingman_record: string;
  success: boolean;
  boss_hp: number;
  end_time: Date;
  is_cm: boolean;
  gwacc_names: string[];
  players: Record<string, PullPlayer>;
  phases: Record<string, PhaseData>;
}

type PhaseData = {
  group_dps: number;
  duration: number;
};

export type PullPlayer = {
  character: string;
  downs: number;
  deaths: number;
  kills: number;
  dps: Record<string, number>;
  dmg_taken: number;
  dmg_at_second: Record<string, number[]>;
  resurrect_time: number;
  subgroup: number;
  profession: string;
  buff_uptime: BuffStats;
  relic: RelicStats;
  role: string;
};

export type BuffStats = {
  [name: string]: {
    [phase: string]: {
      uptime: number;
      states: number[][];
      boon_per_source: Record<string, number[][]> | null;
      boon_provider: Record<string, number> | null;
    }
  }
};

export type RelicStats = {
  name: string;
  phase_stats: Record<string, any>;
};

export type PullDataFunc = (session_id: string) => {
  isLoading: boolean;
  isError: boolean; // Type for error depends on your error handling
  data: PullData[]; // Replace YourDataType with the type of your API response data
};

export const renamePhase = (phase: string) => {
  if (phase === "pre-event") return "Pre-event";
  if (phase === "fullFight") return "Full Fight";
  else return phase;
};

export const usePullData = (id: string) => {
  return useQuery<Pull>(
    ["pulls", id],
    () => {
      return axios.get(`/pulls/pull?pull_id=${encodeURIComponent(id)}`).then((e) => e.data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 30000 /* 30s */,
      refetchInterval: refetchInterval,
      retry: false,
    }
  );
};

export const usePullsData = (session_id: string) => {
  return useQuery<PullData[]>(
    ["sessions/pulls", session_id],
    () => {
      return axios
        .get(`/sessions/pulls?session_id=${encodeURIComponent(session_id)}`)
        .then((e) => e.data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 30000 /* 30s */ /* cacheTime: 300000 */ /* 5min */,
      refetchInterval: refetchInterval,
    }
  );
};

export const usePullsProjectData = (project_id: string) => {
  return useQuery<PullData[]>(
    ["projects/pulls", project_id],
    () => {
      return axios
        .get(`/projects/pulls?project_id=${encodeURIComponent(project_id)}`)
        .then((e) => e.data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 30000 /* 30s */ /* cacheTime: 300000 */ /* 5min */,
      refetchInterval: refetchInterval,
    }
  );
};

export const usePullsDataByBoss = (boss_id: string, after: Date | null, before: Date | null) => {
  return useQuery<Pull[]>(
    ["pulls", boss_id, after, before],
    () => {
      return axios
        .get(`/pulls?boss_id=${encodeURIComponent(boss_id)}&after=${after?.toISOString().split('T')[0]}&before=${before?.toISOString().split('T')[0]}`)
        .then((e) => e.data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 30000 /* 30s */ /* cacheTime: 300000 */ /* 5min */,
      refetchInterval: refetchInterval,
    }
  );
}

export const useSessionPullsDataByBoss = (session_id:string, boss_id: string, is_cm:boolean, number_of_players:number) => {
  return useQuery<Pull[]>(
    ["pulls", session_id, boss_id, is_cm,number_of_players],
    () => {
      return axios
        .get(`/session/boss/pulls?session_id=${encodeURIComponent(session_id)}&boss_id=${encodeURIComponent(boss_id)}&is_cm=${is_cm}&number_of_players=${number_of_players}`)
        .then((e) => e.data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 30000 /* 30s */ /* cacheTime: 300000 */ /* 5min */,
      refetchInterval: refetchInterval,
    }
  );
}

export const usePullsRecord = (boss_id: string, is_cm:boolean, number_of_players: number) => {
  return useQuery<Pull>(
    ["pulls/record", boss_id, is_cm, number_of_players],
    () => {
      return axios
        .get(`/pulls/record?boss_id=${encodeURIComponent(boss_id)}&is_cm=${is_cm}&number_of_players=${number_of_players}`)
        .then((e) => e.data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 30000 /* 30s */ /* cacheTime: 300000 */ /* 5min */,
    }
  );
}

export const usePullsBossSessions = (sessionIds: string[], boss_id: string, is_cm:boolean, number_of_players: number) => {
  return useQuery<Pull[]>(
    ["/session/boss/pulls", boss_id, is_cm, sessionIds,number_of_players],
    () => {
      return axios
        .get(`/session/boss/pulls`,
          {params:
          {
          "boss_id": boss_id,
          "is_cm": is_cm,
            "number_of_players": number_of_players,
            "session_ids": sessionIds.join(',')
        }})
        .then((e) => e.data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 30000 /* 30s */ /* cacheTime: 300000 */ /* 5min */,
      retryDelay: 40000,
    }
  );
}

export const useProjectPullsDataByBoss = (project_id:string, boss_id: string, is_cm:boolean, number_of_players:number) => {
  return useQuery<Pull[]>(
    ["pulls", project_id, boss_id, is_cm,number_of_players],
    () => {
      return axios
        .get(`/project/boss/pulls?project_id=${encodeURIComponent(project_id)}&boss_id=${encodeURIComponent(boss_id)}&is_cm=${is_cm}&number_of_players=${number_of_players}`)
        .then((e) => e.data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 30000 /* 30s */ /* cacheTime: 300000 */ /* 5min */,
      retryDelay: 40000,
    }
  );
}
