

import { Box, Card, CardContent, CardMedia, Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography} from "@mui/material";
import Tile from "../Tile";
import LoadingTile from "./LoadingTile";
import ErrorAlert from "../alerts/ErrorAlert";
import { useEffect, useState } from "react";
import SortingArrow from "../SortingArrow";
import { PullData, usePullData } from "../../utils/pulls";
import { UseQueryResult } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import PullStats from "../plots/PullStats";
import { useBoss } from "../../utils/bosses";

// const HealthBar = ({ maxHp = 100, hp = 100 } = {}) => {
//   const barWidth = (hp / maxHp) * 100;
//   // setHitWidth((damage / hp) * 100);
//   // setBarWidth((hpLeft / maxHp) * 100);
//   return (
//     <BorderLinearProgress variant="determinate" value={barWidth} />
//   );
// };

// const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
//   height: 10,
//   width: '50%',
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: theme.palette.mode === 'light' ? theme.palette.success.light : theme.palette.success.dark,
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: (value? value:0) > 0 ? 'red' : 'green',
//   },
// }));

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   '&:last-child td, &:last-child th': {
//     border: 0,
//   },
// }));


const PullOverview = (props: {
  usePullsFunc: (selected_id: string) => UseQueryResult<PullData[], unknown>,
  isSession: boolean
}
  ) => {
  const { id= "-1" } = useParams();
  const [pulldata, setPulls] = useState<PullData[]>([]);
  const [sortOrder, setSortOrder] = useState<boolean>(false);
  const [currentSort, setCurrentSort] = useState(3);
  const [selectedPullId, setSelectedPullId] = useState<string>("");
  const [selectedPull, setSelectedPull] = useState<PullData | null>(null);
  const [sortingArrays, setSortingArrays] = useState([
    { name: "fight_name", sortDirection: 2 },
    { name: "boss_hp", sortDirection: 2 },
    { name: "duration", sortDirection: 2 },
    { name: "end_time", sortDirection: 2 },
  ]);
  const { data, isLoading, isError, isSuccess } = props.usePullsFunc(id)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { data: pull, isError: isErrorPull } = usePullData(selectedPullId);
  const {data: boss, isLoading: bossLoading} = useBoss(selectedPull? selectedPull.boss_id : "-1");

  
  useEffect(() => {
    if (isSuccess) {
      setPulls(data);
      if (data.length > 0) {
        setSelectedPullId(data[data.length - 1].id)
      };
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (selectedPullId && pulldata !== undefined && pulldata.length > 0) {
      const pull = pulldata.find(pull => pull.id === selectedPullId);
      if (pull) setSelectedPull(pull);
    }
  }, [selectedPullId, pulldata]);

  if (isLoading) return <LoadingTile />

  if (isError) return <ErrorAlert> No Pulls found.</ErrorAlert>;

  let sortedData = [];
    if (currentSort === 0) {
      sortedData = [...pulldata].sort((a, b) => sortOrder ? a.fight_name.localeCompare(b.fight_name) : b.fight_name.localeCompare(a.fight_name));
    } else if (currentSort === 1) {
      sortedData = [...pulldata].sort((a, b) => sortOrder ? a.boss_hp - b.boss_hp : b.boss_hp - a.boss_hp);
    } else if (currentSort === 2) {
      sortedData = [...pulldata].sort((a, b) => sortOrder ? a.duration - b.duration : b.duration - a.duration);
    } else {
      sortedData = [...pulldata].sort((a, b) => sortOrder ? new Date(a.end_time).getTime() - new Date(b.end_time).getTime() : new Date(b.end_time).getTime() - new Date(a.end_time).getTime());
    }
  
  const handleSortByPull = () => {
    handleSortChange("fight_name");
    setCurrentSort(0);
  };

  const handleSortByBossHP = () => {
    handleSortChange("boss_hp");
    setCurrentSort(1);
  };

  const handleSortByDuration = () => {
    handleSortChange("duration");
    setCurrentSort(2);
  }

  const handleSortByEndTime = () => {
    handleSortChange("end_time");
    setCurrentSort(3);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const resetArrows = () => {
    const newArray = [...sortingArrays].map(array => {
      array.sortDirection = 2
      return array;
    })
    setSortingArrays(newArray); 
  }

  const handleSortChange = (arrayName: string) => {
    const newArray = [...sortingArrays];
    const arrayIndex = newArray.findIndex(array => array.name === arrayName);
    if (sortingArrays[arrayIndex].sortDirection === 2) {
      resetArrows();
      newArray[arrayIndex].sortDirection = 0;
    }
    else newArray[arrayIndex].sortDirection = sortingArrays[arrayIndex].sortDirection === 0 ? 1 : 0;

    setSortingArrays(newArray);
    setSortOrder(newArray[arrayIndex].sortDirection === 0 ? true : false);
  }

  return (
    <Tile style={{ overflow: 'auto' }}>
      {pull ?
        (
        
          <Grid height={400}>
            <Card sx={{ display: 'flex' }} style={{ border: "none", boxShadow: "none", maxHeight: "100%", minWidth:"100%" }}>
              <Box style={{ width: '66%', flexDirection: 'column'}}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="h5">
                  {pull?.fight_name} - {pull?.success ? <CheckCircleIcon color="success" /> : <CloseIcon color="error" />}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" component="div">
                    {pull?.success ? "Success" : Object.keys(pull.phases)[Object.keys(pull.phases).length -1]}
                  </Typography>
                  
                  <Grid item xs={12} sm={12} md={6} height={280} width={"80%"}>
                    <PullStats pull={pull} sumOfBest={{}} phase={Object.keys(pull.phases)[Object.keys(pull.phases).length -1]} onlyPull={true} />
                  </Grid>
                  </CardContent>
                
              </Box>
              <Box sx={{ width: '33%' }}>
                {bossLoading ? <></> :
                <CardMedia
                    component="img"
                    style={{ marginLeft: "auto" }}
                    sx={{ width: 150 }}
                    image={boss?.fight_icon ? boss.fight_icon : "https://cdn.discordapp.com/avatars/354331083229364226/6a29ed1d936dfcfeff5bfc477c8a4537?size=1024"}
                    alt=""
                  />
                }
                </Box>
              
              {/* <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 300 }} aria-label="customized table">
                    <TableHead>
                    </TableHead>
                    <TableBody>
                    {Object.entries(pull.players).map(([player, data]) => (
                        <StyledTableRow key={player}>
                          <StyledTableCell component="th" scope="row">
                            {player}
                        </StyledTableCell>
                          <StyledTableCell align="right">{data.dps[Object.keys(data.dps)[0]]}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> */}
            </Card>
        </Grid>
    )
        : <Grid height={400}>
          <Card sx={{ display: 'flex' }} style={{ border: "none", boxShadow: "none", maxHeight: "100%", minWidth:"100%" }}>
              <Box style={{ width: '66%', flexDirection: 'column'}}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="h5">
                  {selectedPull?.fight_name} - {selectedPull?.success ? <CheckCircleIcon color="success" /> : <CloseIcon color="error" />}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" component="div">
                </Typography>
                <Box paddingTop={2} >
                {isErrorPull ? 
                    <ErrorAlert >Could not load pull data.</ErrorAlert> :
                    <LoadingTile />
                  }
                  </Box>
                  </CardContent>
                
              </Box>
              <Box sx={{ width: '33%' }}>
                <CardMedia
                    component="img"
                    style={{ marginLeft: "auto" }}
                    sx={{ width: 150 }}
                    image={boss?.fight_icon ? boss.fight_icon : ""}
                    alt=""
                      />
                </Box>
              
              {/* <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 300 }} aria-label="customized table">
                    <TableHead>
                    </TableHead>
                    <TableBody>
                    {Object.entries(pull.players).map(([player, data]) => (
                        <StyledTableRow key={player}>
                          <StyledTableCell component="th" scope="row">
                            {player}
                        </StyledTableCell>
                          <StyledTableCell align="right">{data.dps[Object.keys(data.dps)[0]]}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> */}
            </Card>
        </Grid>}
      {/* <TableContainer component={Paper} style={{ overflow: 'auto' }}> */}
        <Table className="table justify-content-between" size={'medium'} >
          <TableHead>
            <TableRow style={{ verticalAlign: 'center' }}>
              <TableCell scope="col" onClick={handleSortByPull} > <div style={{ display: 'flex',alignItems: 'center' }}> <span style={{ marginRight: '8px' }}>Fight </span><SortingArrow value={sortingArrays[0].sortDirection} ></SortingArrow> </div></TableCell>
              <TableCell scope="col" onClick={handleSortByBossHP}><div style={{ display: 'flex',alignItems: 'center' }}> <span style={{ marginRight: '8px' }}>HP </span><SortingArrow value={sortingArrays[1].sortDirection} ></SortingArrow></div></TableCell>
              <TableCell scope="col" onClick={handleSortByDuration}><div style={{ display: 'flex',alignItems: 'center' }}> <span style={{ marginRight: '8px' }}>Duration </span><SortingArrow value={sortingArrays[2].sortDirection} ></SortingArrow></div></TableCell>
              {/* <TableCell scope="col" ><div style={{ display: 'flex',alignItems: 'center' }}> <span style={{ marginRight: '8px' }}>Last Phase </span></div></TableCell> */}
              <TableCell scope="col" onClick={handleSortByEndTime}><div style={{ display: 'flex',alignItems: 'center' }}> <span style={{ marginRight: '8px' }}>End </span><SortingArrow value={sortingArrays[3].sortDirection} ></SortingArrow></div></TableCell>
              <TableCell scope="col">Link </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isSuccess && sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((pull) => (
              <TableRow key={pull.id} style={{ height: (45), cursor: "pointer", }} onClick={() => setSelectedPullId(pull.id)}>
                <TableCell >{pull.fight_name}</TableCell>
                <TableCell >{pull.boss_hp}%</TableCell>
                <TableCell >{pull.duration}</TableCell>
                {/* <TableCell >{pull.last_phase}</TableCell> */}
                <TableCell >{new Date(pull.end_time).toLocaleString()}</TableCell>
                <TableCell onClick={(e) => e.stopPropagation()}><a href={pull.id} target="_blank" rel="noreferrer">DPS Report</a></TableCell>
              </TableRow>
              ))}
          {isSuccess && Array.from(Array(rowsPerPage - Math.min(rowsPerPage, sortedData.length - page * rowsPerPage)).keys()).map((_, index) => (
            <TableRow key={index} style={{ height: (45)}}>
              <TableCell colSpan={5} sx={{ borderBottom: "none" }}/>
            </TableRow>
          ))}
          </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
        {/* </TableContainer> */}
    </Tile>
  );
}

export default PullOverview;