import { tiles } from "../utils/tile";
import { Suspense } from "react";
import Loading from "../components/Loading";
import TilesLayout from "../components/TilesLayout";
import SessionParticipation from "../components/tiles/SessionParticipation";

const Guild = () => {

  const { defaultTiles, defaultLayout } = tiles([
    { el: <SessionParticipation/>, id: "a", name: "Guild Info", main: { x: 0, y: 0, w: 10, h: 16 }, mobile: { x: 0, y: 0, w: 24, h: 12 } },
    ])

  return (
    <div className="App_">
      <Suspense fallback={<Loading />}>
        <TilesLayout layoutId="guild-v1" defaultLayout={defaultLayout} defaultTiles={defaultTiles} />
      </Suspense>
    </div>
  )
}

export default Guild