import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type Guild = {
  /**
   * Incident id
   */
  id: string;
  /**
   * Guild name
   */
  name: string;
  /**
   * url of the icon
   */
  icon_url: string;
};

export type GuildMember = {
    /**
     * Account name
     */
    account_name: string;
    /**
     * Discord id
     * @example 123456789012345678
     *  */
    discord_id: string;
    /**
     * Guild id
     */
    guild_id: string;

    /**
     * Guild name
     */
    guild_name: string;
};

export const useGuilds = () => {
    return useQuery<Guild[]>(
        ["bosses"],
        () => {
            return axios.get("/guilds/all").then((e) => e.data);
        },
        { refetchInterval: 10000 /* 10s */ }
    );
};

export const useGuild = (id: string) => {
    return useQuery<Guild>(
        ["boss", id],
        () => {
            return axios.get(`/guilds?guild_id=${id}`).then((e) => e.data);
        }
    );
}

export const useGuildMembers = () => {
    return useQuery<GuildMember[]>(
        ["guild/members"],
        () => {
            return axios.get(`/guilds/members`).then((e) => e.data);
        }
    );
}
